import Authorization from "../components/auth/authorization";
import LicenseList from "../components/license-list/license-list";
import { initializeIcons } from "@fluentui/react";
import { UserPermission } from "../constants/permissions.constants";
import { useContainerStyles } from "../@styles/container.styles";

const AllLicensesPage = () => {
  const contClasses = useContainerStyles();
  initializeIcons();
  return (
    <Authorization>
      <section
        key={UserPermission.P_LICENSE_LIST}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div style={{ marginTop: "3rem" }}>
              <div style={{ paddingLeft: "1rem", width: "90%" }}></div>
              <LicenseList />
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default AllLicensesPage;
