import BarChartComponent from "../../components/bar-chart/bar-chart";
import PieChartComponent from "../../components/pie-chart/pie-chart";
import StatsCard from "../../components/stats-card/stats-card";
import { DialogType, initializeIcons } from "@fluentui/react";
import { CloudFrameIconButton } from "@cloudframe/button";
import LineChartComponent from "../../components/line-chart/line-chart";
import ProductComponent from "../../components/product/product";
import { useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  QUERY_GET_LICENSE_STATUS,
  QUERY_SEND_LICENSE_EXPIRY_EMAIL,
} from "../../graphql-query/license.query";
import { padTwoDigits } from "../../utils/numeric.utils";
import useStyles from "./dashboard.styles";
import ListCardItem from "../../components/list-card-item/list-card-item";
import ListItem from "../../components/list-item/list-item";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../utils/date.utils";
import { CloudFrameDialog } from "@cloudframe/dialog";
import Authorization from "../../components/auth/authorization";
import { UserPermission } from "../../constants/permissions.constants";

const sortBasedOnMonth = (items: any[]) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentMonth = new Date().getMonth() + 1;
  const monthMap = monthNames.reduce((map: any, month, index) => {
    map[month] = index < currentMonth ? index + 12 : index;
    return map;
  }, {});

  items.sort((a, b) => monthMap[a.name] - monthMap[b.name]);

  return items;
};

const getFormattedExpiryDate = (license: any) => {
  return (
    <div
      style={{
        fontSize: "14px",
        fontWeight: 400,
        marginLeft: "3.2rem",
        marginTop: ".5rem",
        padding: ".3rem",
        paddingLeft: ".4rem",
        paddingRight: ".7rem",
        //background: "rgba(155,155,150,.5)",
        borderRadius: 50,
        width: "max-content",
      }}
    >
      Expire on <strong>{getFormattedDate(license.expiryDate)}</strong>
    </div>
  );
};

const FETCH_LICENSES_TO_EXPIRE_IN_DAYS = 31;

const Dashboard = () => {
  const [selectedProduct, setSelectedProduct] = useState("Relocate");
  const [fetchLicenseStatus, licenseStatus] = useLazyQuery(
    QUERY_GET_LICENSE_STATUS
  );
  const [sendExpiryEmail, expiryEmailStatus] = useLazyQuery(
    QUERY_SEND_LICENSE_EXPIRY_EMAIL
  );
  const [selectedLicense, setSelectedLicense] = useState<any>({});
  const [showEmailSentDialog, setShowEmailSentDialog] = useState(false);
  const navigate = useNavigate();
  const [parsedLicenseData, setParseLicenseData] = useState<any>({});
  const classes = useStyles();
  const currentMonth = new Date().toLocaleString("en-US", { month: "short" });

  console.log("LICENSE DATA:", parsedLicenseData);

  const barChartData = useMemo(() => {
    if (parsedLicenseData.offlineLicenses) {
      return sortBasedOnMonth(
        Object.keys(parsedLicenseData.offlineLicenses.monthlyBasis).map(
          (key) => ({
            name: key,
            "Embedded Licenses":
              parsedLicenseData.offlineLicenses.monthlyBasis[key],
            Renewed: parsedLicenseData.renewed.monthlyBasis[key],
            Subscriptions: parsedLicenseData.subscriptions.monthlyBasis[key],
            Expired: parsedLicenseData.expired.monthlyBasis[key],
          })
        )
      );
    }
    return [];
  }, [parsedLicenseData]);

  const pieChartData = useMemo(() => {
    if (parsedLicenseData.offlineLicenses) {
      return [
        {
          name: "Embedded Licenses",
          value: parsedLicenseData.offlineLicenses.total || 0,
        },
        {
          name: "Subscriptions",
          value: parsedLicenseData.subscriptions.total || 0,
        },
        { name: "Expired", value: parsedLicenseData.expired.total || 0 },
        { name: "Renewed", value: parsedLicenseData.renewed.total || 0 },
      ];
    }
    return [];
  }, [parsedLicenseData]);

  const lineChartData = useMemo(() => {
    if (parsedLicenseData.offlineLicenses) {
      return sortBasedOnMonth(
        Object.keys(parsedLicenseData.offlineLicenses.monthlyBasis).map(
          (key) => ({
            name: key,
            "Embedded Licenses":
              parsedLicenseData.offlineLicenses.monthlyBasis[key] || 0,
            Subscriptions:
              parsedLicenseData.subscriptions.monthlyBasis[key] || 0,
            Renewed: parsedLicenseData.renewed.monthlyBasis[key] || 0,
            Expired: parsedLicenseData.expired.monthlyBasis[key] || 0,
          })
        )
      );
    }
    return [];
  }, [parsedLicenseData]);

  useEffect(() => {
    (async () => {
      const result = await fetchLicenseStatus({
        variables: {
          expiryDays: FETCH_LICENSES_TO_EXPIRE_IN_DAYS,
          product: selectedProduct,
        },
      });
      setParseLicenseData(JSON.parse(result.data?.licenseStatus || "{}"));
    })();
  }, [selectedProduct]);

  initializeIcons();

  if (licenseStatus.loading) {
    return <p>Loading...</p>;
  }
  if (licenseStatus.error) {
    console.log(licenseStatus.error);
    return <p>Unable to fetch the license status !!!</p>;
  }

  const onClickNotify = async (licenseId: string) => {
    const res = await sendExpiryEmail({ variables: { licenseId } });
    if (res.data.sendLicenseExpiryMail) {
      setShowEmailSentDialog(true);
    }
  };

  return (
    <Authorization>
    <section key={UserPermission.P_DASHBOARD} className={classes.root}>
      <div className={classes.rightContainer}>
        <div className={`${classes.productSelectRoot} dashboard-tile`}>
          <h3 style={{ marginBottom: "2rem", width: "max-content" }}>
            Select Product
          </h3>
          <div className={classes.productSelectContainer}>
            <ProductComponent
              name="Relocate"
              avatar={{ initial: "RL", color: "rgba(200,200,200,.5)" }}
              selected={selectedProduct === "Relocate"}
              onClick={() => setSelectedProduct("Relocate")}
            />
            <ProductComponent
              name="Renovate"
              avatar={{ initial: "RN", color: "rgba(150,100,100,.5)" }}
              selected={selectedProduct === "Renovate"}
              onClick={() => setSelectedProduct("Renovate")}
            />
          </div>
        </div>
        <div
          className={`${classes.productSelectRoot} dashboard-tile`}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          <h3 style={{ marginBottom: "2rem", width: "max-content" }}>
            Customers
          </h3>
          <div
            className={classes.productSelectContainer}
            style={{ gap: "1rem" }}
          >
            <ListCardItem
              title="Microsoft"
              description="Renovate License"
              onClick={() =>
                navigate("/dashboard-client", {
                  state: { customerName: "Microsoft" },
                })
              }
            />
            <ListCardItem
              title="Mastercard"
              description="Relocate License"
              onClick={() =>
                navigate("/dashboard-client", {
                  state: { customerName: "Mastercard" },
                })
              }
            />
            <ListCardItem
              title="Wipro"
              description="Relocate License"
              onClick={() =>
                navigate("/dashboard-client", {
                  state: { customerName: "Wipro" },
                })
              }
            />
            <ListCardItem
              title="US Bank"
              description="Renovate License"
              onClick={() =>
                navigate("/dashboard-client", {
                  state: { customerName: "Bank" },
                })
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "2rem",
          marginTop: 0,
          marginLeft: "2%",
          marginRight: 0,
        }}
      >
        <div
          style={{
            maxWidth: "91%",
            marginTop: "0rem",
            backgroundColor: "#fff",
            padding: "1rem",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
          className="dashboard-tile"
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {/* <CloudFrameStack horizontal tokens={{ childrenGap: "1rem" }} style={{flexWrap: "wrap"}}> */}
            <StatsCard
              value={padTwoDigits(
                parsedLicenseData?.offlineLicenses?.total || 0
              )}
              title={`+${padTwoDigits(
                parsedLicenseData?.offlineLicenses?.monthlyBasis?.[
                  currentMonth
                ] || 0
              )} This month`}
              description="Embedded Licenses"
              icon={
                <CloudFrameIconButton
                  disabled
                  iconProps={{
                    iconName: "Emoji",
                    className: classes.icon,
                    // style: {
                    //   color: "green",
                    //   transform: "scale(1.7)",
                    //   marginBlock: "5px",
                    //   marginRight: "1.5rem",
                    //   backgroundColor: "rgba(100,300,100,.5)",
                    //   padding: "10px",
                    //   borderRadius: 100,
                    // },
                  }}
                  title="Active Offline Licenses "
                />
              }
            />{" "}
            <StatsCard
              value={padTwoDigits(parsedLicenseData?.subscriptions?.total || 0)}
              title={`+${padTwoDigits(
                parsedLicenseData?.subscriptions?.monthlyBasis?.[
                  currentMonth
                ] || 0
              )} This month`}
              description="Active Subscriptions"
              icon={
                <CloudFrameIconButton
                  disabled
                  iconProps={{
                    iconName: "Emoji",
                    className: classes.icon,
                    style: {
                      color: "rgba(140,100,100,1)",
                      backgroundColor: "rgba(140,100,100,.5)",
                    },
                  }}
                  title="Active Licenses"
                />
              }
            />{" "}
            <StatsCard
              value={padTwoDigits(parsedLicenseData?.renewed?.total || 0)}
              title={`+${padTwoDigits(
                parsedLicenseData?.renewed?.monthlyBasis?.[currentMonth] || 0
              )} This month`}
              description="Renewed Licenses"
              icon={
                <CloudFrameIconButton
                  disabled
                  iconProps={{
                    iconName: "Emoji2",
                    className: classes.icon,
                    style: {
                      color: "blue",
                      backgroundColor: "rgba(100,100,300,.5)",
                    },
                  }}
                  title="Renew License"
                />
              }
            />{" "}
            <StatsCard
              value={padTwoDigits(parsedLicenseData?.expired?.total || 0)}
              title={`+${padTwoDigits(
                parsedLicenseData?.expired?.monthlyBasis?.[currentMonth] || 0
              )} This month`}
              description="Expired Licenses"
              icon={
                <CloudFrameIconButton
                  disabled
                  iconProps={{
                    iconName: "EmojiDisappointed",
                    className: `${classes.icon}`,
                    style: {
                      color: "red",
                      backgroundColor: "rgba(300,100,100,.5)",
                      padding: "10px",
                    },
                  }}
                  title="Renew License"
                />
              }
            />
            <div className={classes.lastIcon}>
              <StatsCard
                value={padTwoDigits(
                  parsedLicenseData?.expiresNextMonth?.total || 0
                )}
                description={`Expires in ${FETCH_LICENSES_TO_EXPIRE_IN_DAYS} Days`}
                icon={
                  <CloudFrameIconButton
                    disabled
                    iconProps={{
                      iconName: "Sad",
                      className: `${classes.icon}`,
                      style: {
                        color: "rgba(250,100,10,1)",
                        backgroundColor: "rgba(250,100,10,.5)",
                        padding: "10px",
                      },
                    }}
                    title="Renew License"
                  />
                }
              />
            </div>
          </div>
          {/* </CloudFrameStack> */}
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "2rem",
            marginTop: "1rem",
            maxWidth: "91%",
          }}
          className="dashboard-tile "
        >
          <h3 style={{ marginTop: "1rem", marginLeft: "1rem" }}>
            Monthly statistics
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "3rem",
              gap: "5rem",
              marginBottom: "0rem",
            }}
          >
            <div
              style={{
                width: "30rem",
                height: "25rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                marginRight: "2rem",
              }}
            >
              <div style={{ color: "grey", marginRight: "2rem" }}>
                New Licenses
              </div>
              <BarChartComponent data={barChartData} />
            </div>
            <div
              style={{
                width: "30rem",
                height: "25rem",
                border: "1px solid white",
                marginTop: "0rem",
              }}
            >
              <div style={{ color: "grey" }}>All Licenses</div>
              <PieChartComponent data={pieChartData} />
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "2rem",
            marginTop: "1rem",
            maxWidth: "91%",
          }}
          className="dashboard-tile "
        >
          <div
            style={{
              height: "25rem",
              marginTop: "3rem",
              marginBottom: "1rem",
              marginLeft: "1rem",
            }}
          >
            <LineChartComponent data={lineChartData} />
          </div>
        </div>

          {parsedLicenseData?.expiresNextMonth?.total && (
            <div
              style={{
                backgroundColor: "#fff",
                padding: "2rem",
                marginTop: "1rem",
                maxWidth: "50rem",
              }}
              className="dashboard-tile "
            >
              <div
                style={{
                  height: "auto",
                  marginTop: "3rem",
                  marginBottom: "1rem",
                  marginLeft: "1rem",
                }}
              >
                <h3 style={{ marginTop: "2rem", marginLeft: "1rem" }}>
                  {`Licenses to Expire in ${FETCH_LICENSES_TO_EXPIRE_IN_DAYS} days`}
                </h3>
                <div
                  style={{
                    marginTop: "3rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {parsedLicenseData.expiresNextMonth.licenses.map(
                    (license: any) => (
                      <ListItem
                        key={license.id}
                        id={license.id}
                        title={license.customerName}
                        description={`${
                          license.product.charAt(0) +
                          license.product.slice(1).toLowerCase()
                        } License`}
                        content={getFormattedExpiryDate(license)}
                        buttonTitle={"Notify"}
                        onClickButton={async (id: string) => {
                          setSelectedLicense(license);
                          await onClickNotify(id);
                        }}
                        secondaryValue={`${
                          license.expiryReminderEmailSentOn
                            ? "Reminder Sent On: " +
                              getFormattedDate(
                                license.expiryReminderEmailSentOn
                              )
                            : ""
                        }`}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <CloudFrameDialog
          hidden={!showEmailSentDialog}
          onDismiss={() => {
            setShowEmailSentDialog(false);
            setSelectedLicense({});
          }}
          dialogContentProps={{
            type: DialogType.normal,
            title: "License expiry Reminder Sent",
            closeButtonAriaLabel: "Close",
            subText: `The License Expiry reminder notification email is sent to customer ${selectedLicense?.customerName} at ${selectedLicense?.email} successfully.`,
          }}
          modalProps={{
            titleAriaId: "labelId",
            subtitleAriaId: "subTextId",
            isBlocking: false,
            styles: { main: { maxWidth: 400 } },
            dragOptions: undefined,
          }}
          primaryButton={{
            text: "Close",
            onClick: () => {
              setShowEmailSentDialog(false);
              setSelectedLicense({});
            },
          }}
        />
      </section>
    </Authorization>
  );
};

export default Dashboard;
