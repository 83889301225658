import { gql } from "@apollo/client";

export const QUERY_GET_ROLES = gql`
query GetRoles {
  getRoles {
    id
    name
  }
}
`;

export const QUERY_GET_ROLES_WITH_PERMISSIONS = gql`
query Query {
  getRoles {
    id
    name
    permissions {
    id
    name  
    }
  }
}
`;

export const QUERY_GET_ROLE = gql`
  query GetLicense($id: Float!) {
    getLicense(id: $id) {
      id
      customerName
      email
      licenseType
      isCustomerReseller
      product
      linesOfCode
      linesOfCodeCategory
      effectiveDate
      expiryDate
      supportLevel
      productTrainingProvided
      isEvaluationLicense
      consultingHours
      isOfflineLicense
    }
  }
`;
