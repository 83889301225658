import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const example = [
  {
    name: "Jan",
    New: 15,
    Renewed: 2,
  },
  {
    name: "Feb",
    New: 12,
    Renewed: 2,
  },
  {
    name: "Mar",
    New: 20,
    Renewed: 5,
  },
  {
    name: "Apr",
    New: 50,
    Renewed: 20,
  },
  {
    name: "May",
    New: 30,
    Renewed: 12,
  },
  {
    name: "Jun",
    New: 25,
    Renewed: 1,
  },
  {
    name: "Jul",
    New: 45,
    Renewed: 8,
  },
];

const BarChartComponent = (props: any) => {
  const {data = []} = props;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart 
        data={data}
        width={100}
        title="New license"
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Expired" fill="rgba(255,50,50,.5)" />
        <Bar dataKey="Renewed" fill="#8884d8" />
        <Bar dataKey="Embedded Licenses" fill="#82ca9d" />
        <Bar dataKey="Subscriptions" fill="#82c" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
