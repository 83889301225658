import { CloudFrameText } from "@cloudframe/text";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  CloudFramePrimaryButton,
  CloudFrameDefaultButton,
} from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { initializeIcons, IIconProps, FontIcon } from "@fluentui/react";
import * as styles from "./invite-form.style";

import { SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  MUTATION_INVITE_USER,
  QUERY_GET_INVITED_USERS,
} from "../../graphql-query/user.query";
import { useContainerStyles } from "../../@styles/container.styles";
import Authorization from "../auth/authorization";
import { QUERY_GET_ROLES } from "../../graphql-query/role.query";
import { UserPermission } from "../../constants/permissions.constants";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { useFormStyles } from "../../@styles/form.style";
import { checkSsoProfile } from "../../utils/ms.graph.utils";
import { useListStyles } from "../../@styles/list.styles";

const InviteForm = () => {
  initializeIcons();
  const defaultRoleName = "Admin";
  const [options, setOptions] = useState<any[]>([]);

  const [userEmail, setUserEmail] = useState("");
  const [selectedRoleKey, setSelectedRoleKey] = useState("");
  const [userRole, setUserRole] = useState(defaultRoleName);
  const [inviteMsg, setInviteMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [activeItem, setActiveItem] = useState<any>({});
  const [errors, setErrors] = useState<any>([]);
  // @ts-ignore
  const [displayData, setDisplayData] = useState([]);
  const [fullDisplayData, setFullDisplayData] = useState([]);
  const userResult = useQuery(QUERY_GET_INVITED_USERS);
  const roleListQuery = useQuery(QUERY_GET_ROLES);
  const [invitesUser] = useMutation(MUTATION_INVITE_USER);
  const contClasses = useContainerStyles();
  const listClasses = useListStyles();
  const [formSubmitAttempted, setFormSubmitAttempted] = useState(false);

  const searchIcon: IIconProps = { iconName: "Search" };
  const formStyles = useFormStyles();

  const [errorMessageEmailValidation, setErrorMessageEmailValidation] =
    useState("");

  const getKeyForRole = (role: string) => {
    return `P_${role.trim().replaceAll(" ", "_").toUpperCase()}`;
  };

  const getInvitationDate = (dateNum) => {
    const date = new Date();
    date.setMilliseconds(dateNum);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (userResult.data) {
      const list = userResult.data?.getInvitations?.map((d: any) => {
        return {
          key: d.userEmail,
          email: d.userEmail,
          accessRole: d.role,
          status: d.validated ? "Accepted" : "Pending",
          invitationDate: getInvitationDate(d.codeGenerationTime),
        };
      });
      setFullDisplayData(list);
      setDisplayData(list);
    }
  }, [userResult.data]);

  useEffect(() => {
    const roles = roleListQuery.data?.getRoles || [];
    const optionList = roles.map((item: any) => {
      return { key: `${getKeyForRole(item.name)}`, text: item.name };
    });
    setOptions(optionList);
    setSelectedRoleKey(getKeyForRole(defaultRoleName));
  }, [roleListQuery.data]);

  const columns: IColumn[] = [
    {
      key: "column1",
      name: "Icon",
      isIconOnly: true,
      minWidth: 16,
      maxWidth: 16,
      onRender: (item) => (
        <FontIcon
          aria-label="User"
          iconName="contact"
          className={styles.userTable().userIcon}
        />
      ),
    },
    {
      key: "column2",
      name: "Email",
      fieldName: "email",
      minWidth: 310,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      // isSorted: true,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      //   onColumnClick: this._onColumnClick,
      data: "string",
      isPadded: true,
    },
    {
      key: "column3",
      name: "Status",
      fieldName: "status",
      minWidth: 80,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      // isSorted: true,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      // isFiltered: true,
      //   onColumnClick: this._onColumnClick,
      data: "string",
      isPadded: true,
    },
    {
      key: "column4",
      name: "Access Role",
      fieldName: "accessRole",
      minWidth: 120,
      maxWidth: 300,
      isRowHeader: true,
      isResizable: true,
      // isSorted: true,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      // isFiltered: true,
      //   onColumnClick: this._onColumnClick,
      data: "string",
      isPadded: true,
    },
    {
      key: "column5",
      name: "Invitation Date",
      fieldName: "invitationDate",
      minWidth: 120,
      maxWidth: 300,
      isRowHeader: true,
      isResizable: true,
      // isSorted: true,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      // isFiltered: true,
      //   onColumnClick: this._onColumnClick,
      data: "string",
      isPadded: true,
    },
  ];

  const onRoleChange = useCallback(
    (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      option?: IChoiceGroupOption | any
    ) => {
      setSelectedRoleKey(option?.key);
      setUserRole(option?.text);
      setErrors({ ...errors, role: undefined });
    },
    []
  );

  const onSubmitEntry = async () => {
    setInviteMsg("");
    setErrorMsg("");
    setFormSubmitAttempted(true);
    const isActiveSsoUser: boolean = await checkSsoProfile(userEmail);
    if (isActiveSsoUser) {
      try {
        if (
          userEmail != "" &&
          userEmail != undefined &&
          errorMessageEmailValidation == ""
        ) {
          const invitesUserPayload = {
            variables: {
              role: userRole,
              email: userEmail,
            },
          };

          const response = await invitesUser(invitesUserPayload);
          if (response.errors && response.errors.length) {
            throw new Error(response.errors[0]?.message);
          }
          setInviteMsg(`Email sent successfully to ${userEmail}.`);
          let array: any[] = [];
          array.push({
            key: response.data.inviteUser.userEmail,
            email: response.data.inviteUser.userEmail,
            accessRole: response.data.inviteUser.role,
            status: response.data.inviteUser.validated ? "Accepted" : "Pending",
          });
          for (let i = 0; i < displayData.length; i++) {
            array.push(displayData[i]);
          }

          // @ts-ignore
          setDisplayData(array);
          // @ts-ignore
          setFullDisplayData(array);
        } else {
          errorMessageEmailValidation == "" &&
            setErrorMessageEmailValidation("Please enter user email.");
        }
      } catch (e: any) {
        setErrorMsg(e.message);
      }
    } else {
      setErrorMessageEmailValidation(
        "Please enter valid SSO enabled user email."
      );
    }
    setFormSubmitAttempted(false);
  };

  const onFilterChange = (event: any, field: any) => {
    if (!event.currentTarget.value) {
      setTimeout(() => {
        setDisplayData(fullDisplayData);
      });
    }
    let textFilter = event.currentTarget.value;
    let filteredData = textFilter
      ? fullDisplayData.filter(
          (i: any) =>
            i.email.toLowerCase().indexOf(textFilter.toLowerCase()) > -1
        )
      : displayData;
    setDisplayData(filteredData);
  };

  const onReset = () => {
    setUserEmail("");
    setSelectedRoleKey(getKeyForRole(defaultRoleName));
    setUserRole(defaultRoleName);
    setErrorMessageEmailValidation("");
    setInviteMsg("");
    setErrorMsg("");
  };

  const onEdit = () => {
    // @ts-ignore
    activeItem.item.email && setUserEmail(activeItem.item.email);
    // @ts-ignore
    let roleOption = options.filter(
      (i: any) => i.text === activeItem.item.accessRole
    );
    setSelectedRoleKey(roleOption[0].key);
    setUserRole(roleOption[0].text);
  };

  return (
    <>
      <Authorization>
        <section
          key={UserPermission.P_INVITE_USER}
          className={contClasses.sectionContainer}
        >
          <div className={"dashboard-tile " + contClasses.boxContainer}>
            <div className={contClasses.root}>
              <div className={contClasses.subContainer}>
                <h3>Invite New User</h3>
                <CloudFrameTextBox
                  label="Email"
                  placeholder="...@cloudframe.com"
                  value={userEmail}
                  required={true}
                  errorMessage={errorMessageEmailValidation}
                  className={contClasses.mb8}
                  onChange={(e: any) => {
                    const email = e.target.value;
                    if (!/^\w+([\.-]?\w+)*@cloudframe.com$/.test(email)) {
                      setErrorMessageEmailValidation("Invalid email.");
                    } else {
                      setErrorMessageEmailValidation("");
                    }
                    setUserEmail(email);
                  }}
                />
                <CloudFrameDropdown
                  label="Access Role"
                  placeholder="Select access Role"
                  required
                  errorMessage={errors.role}
                  defaultSelectedKey={[
                    options?.length ? options[0].key : defaultRoleName,
                  ]}
                  onChange={onRoleChange}
                  className={contClasses.mb8}
                  options={options}
                />
                <div className={contClasses.flexEndContainer}>
                  <CloudFrameText
                    variant="medium"
                    textInfo={inviteMsg}
                    className={styles.formFooter().inviteMessage}
                  />
                  <CloudFrameText
                    variant="medium"
                    textInfo={errorMsg}
                    className={formStyles.errorMessage}
                  />
                  <CloudFramePrimaryButton
                    text="Invite"
                    onClick={() => onSubmitEntry()}
                    disabled={formSubmitAttempted}
                  />
                  <CloudFrameDefaultButton
                    text="Reset"
                    onClick={() => onReset()}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          key={UserPermission.P_INVITATION_LIST}
          className={contClasses.sectionContainer}
        >
          <div className={"dashboard-tile " + contClasses.boxContainer}>
            <div className={contClasses.root}>
              <h3>Invitation List</h3>
              <CloudFrameTextBox
                placeholder="Search"
                iconProps={searchIcon}
                onChange={(e) => onFilterChange(e, "email")}
                className={styles.userTable().searchBar}
              />

              <CloudFrameDataTable
                items={displayData}
                selectionMode={SelectionMode.none}
                columns={columns}
                className={listClasses.tableBody}
                onActiveItemChanged={(item, index) =>
                  setActiveItem({ item, index })
                }
                compact
              />
            </div>
          </div>
        </section>
      </Authorization>
    </>
  );
};

export default InviteForm;
