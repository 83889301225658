import { gql } from "@apollo/client";

export const MUTATION_CREATE_LICENSE = gql`
  mutation Mutation($data: CreateLicenseInputType!) {
    createLicense(data: $data) {
      id
      email
      licenseType
      customerName
      isCustomerReseller
      product
      linesOfCode
      effectiveDate
      expiryDate
      supportLevel
      productTrainingProvided
      isEvaluationLicense
      consultingHours
      linesOfCodeCategory
      jiraTicket
    }
  }
`;

export const MUTATION_REVOKE_LICENSE = gql`
  mutation RevokeLicense($id: Float!) {
    revokeLicense(id: $id) {
      id
      licenseType
      customerName
    }
  }
`;

export const MUTATION_APPROVE_LICENSE = gql`
  mutation ApproveLicense($id: Float!) {
    approveLicense(id: $id) {
      status
      message
    }
  }
`;

export const MUTATION_DECLINE_LICENSE = gql`
  mutation DeclineLicense($id: Float!, $declineReason: String!) {
    declineLicense(id: $id, declineReason: $declineReason) {
      status
      message
    }
  }
`;

export const MUTATION_EDIT_LICENSE = gql`
  mutation RenewLicense($data: EditLicenseInputType!) {
    renewLicense(data: $data) {
      id
      customerName
      licenseType
      isCustomerReseller
      product
      linesOfCode
      expiryDate
      effectiveDate
      supportLevel
      productTrainingProvided
      isEvaluationLicense
      consultingHours
    }
  }
`;
