import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const saveStringAsTxt = (data: string, fileName: string) => {
  const blob = new Blob([data], { type: "text/plain" });

  saveAs(blob, `${fileName}.txt`);
};

export const downloadHtmlAsPdf = (reportContainerId, reportFileName): any => {
  setTimeout(() => {
    const input = document.getElementById(reportContainerId) as HTMLElement;
    html2canvas(input).then((canvas) => {
      const imgWidth = 190;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const imgData = canvas.toDataURL("img/png");
      let position = 10;
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`${reportFileName}.pdf`);
    });
  }, 500);
};

export const loadCss = (filename) => {
  var cssNode = document.createElement("link");
  cssNode.setAttribute("rel", "stylesheet");
  cssNode.setAttribute("type", "text/css");
  cssNode.setAttribute("href", filename);
  document.getElementsByTagName("head")[0].appendChild(cssNode);
};

const updatePermissions = (componentKeys: string[]) => {
  componentKeys.forEach((componentKey) => {
    const componentName =
      componentKey.charAt(0) +
      componentKey.substring(1).replaceAll("_", " ").toLocaleLowerCase();
    
  });
};
