const emailRegex = /^[a-zA-Z0-9._-]+@cloudframe.com$/;

export const getInitials = (name: string): string => {
  const names = name.split(" ");
  const initials = names.map((n) => n.charAt(0).toUpperCase()).join("");
  return initials;
};

export const isEmailValid = (email: string): boolean => {
  return emailRegex.test(email);
};

export const parseJson = (json: string): any => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
};

export const isBlank = (value: any): boolean => {
  return value === undefined || value === null || value === '' || Number.isNaN(value);
}
