import { getFormattedDate } from "../../../utils/date.utils";
import { ILicenseDetail } from "./license-detail.interface";

const licenseStatusColors: any = {
  PENDING: "rgba(10,250,30,.3)",
  ACTIVE: "rgba(10,250,30,.3)",
  REVOKED: "rgba(200,80,60,.3)",
  EXPIRED: "rgba(210,50,30,.3)",
};

const LicenseDetail = (props: ILicenseDetail) => {
  const { customerName, licenseData } = props;
  return (
    <div className="dashboard-tile" style={{ flexGrow: 1, padding: 0 }}>
      <h3
        style={{
          margin: 0,
          padding: "1.3rem",
          borderBottom: "1px solid #eee",
          color: "black",
        }}
      >
        {customerName ? "License details" : "Your License details"}
      </h3>
      <div style={{ padding: "1rem" }}>
        <div style={{ height: "16rem" }}>
          <div
            style={{
              width: "90%",
              display: "flex",
              color: "grey",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "60%" }}>
              <h4 style={{ borderBottom: "1px solid #eee", padding: "4px" }}>
                Type:
              </h4>
              <h4 style={{ borderBottom: "1px solid #eee", padding: "4px" }}>
                Effective date:
              </h4>
              <h4 style={{ borderBottom: "1px solid #eee", padding: "4px" }}>
                Lines:
              </h4>
              <h4 style={{ borderBottom: "1px solid #eee", padding: "4px" }}>
                Support:
              </h4>
              <h4 style={{ borderBottom: "0px solid #eee", padding: "4px" }}>
                Consulting Hours:
              </h4>
            </div>
            <div style={{ width: "40%" }}>
              <h4
                style={{
                  borderBottom: "1px solid #eee",
                  padding: "4px",
                  borderRadius: 20,
                }}
              >
                {licenseData.product}
              </h4>
              <h4 style={{ borderBottom: "1px solid #eee", padding: "4px" }}>
                {getFormattedDate(licenseData.effectiveDate)}
              </h4>
              <h4 style={{ borderBottom: "1px solid #eee", padding: "4px" }}>
                {licenseData.linesOfCode}
              </h4>
              <h4 style={{ borderBottom: "1px solid #eee", padding: "4px" }}>
                {licenseData.supportLevel}
              </h4>
              <h4 style={{ borderBottom: "0px solid #eee", padding: "4px" }}>
                {licenseData.consultingHours}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            borderTop: "1px solid #eee",
            borderRight: "1px solid #eee",
            width: "100%",
          }}
        >
          <div
            style={{
              padding: ".5rem",
              marginLeft: "1rem",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                margin: 0,
                color: "grey",
                marginTop: "0",
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                flexGrow: 1,
              }}
            >
              {!customerName && (
                <a
                  href="/contact-us"
                  style={{
                    textDecoration: "none",
                    width: "53%",
                    cursor: "pointer",
                  }}
                >
                  Contact us
                  <span style={{ fontSize: "1.8rem" }}>&rarr;</span>
                </a>
              )}
              <div
                style={{
                  background: licenseStatusColors[licenseData.status],
                  fontSize: "14px",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                  paddingTop: ".5rem",
                  paddingBottom: ".5rem",
                  borderRadius: 20,
                }}
              >
                {licenseData.status}
              </div>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseDetail;
