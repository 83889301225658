import { makeStyles } from "@fluentui/react-components";

export const useFormStyles = makeStyles({
  formBody1: {
    maxWidth: "500px",
    backgroundColor: "teal",
  },
  formBody: {
    paddingLeft: "2rem",
    paddingRight: "3rem",
    paddingBottom: "4rem",
    paddingTop: "2rem",
    // marginBlock: "0 auto",
    backgroundColor: "#fff",
    borderLeftRadius: "25px",
    borderRightRadius: "25px",
    borderTopRadius: "25px",
    borderBottomRadius: "25px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "5rem",
  },
  userIcon: {
    transform: "scale(1.5)",
    // marginBlock: '5px'
  },
  editAction: {
    transform: "scale(1.5)",
    color: "blue",
  },
  revokeAction: {
    transform: "scale(1.5)",
    color: "red",
  },
  searchBar: {
    maxWidth: "20%",
    marginTop: "20px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  tableBody: {
    width: "auto",
    height: "240px",
    overflowY: "scroll",
    backgroundColor: "#fff",
    display: "block",
    "@media(min-width: 1250px)": {
      overflowX: "hidden",
    },
  },
  errorMessage: {
    color: 'red',    
  }
});
