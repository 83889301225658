import { initializeIcons } from "@fluentui/react";
import LicenseAuditTrail from "../../components/audit-trail/license-audit-trail";
import Authorization from "../../components/auth/authorization";
import { UserPermission } from "../../constants/permissions.constants";
import { useContainerStyles } from "../../@styles/container.styles";
import AppLog from "../../components/app-log/app-log";

const AppLogPage = () => {
  const contClasses = useContainerStyles();
  initializeIcons();
  return (
    <Authorization>
      <section
        key={UserPermission.P_APP_LOGS}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div style={{ marginTop: "3rem" }}>
              <div style={{ paddingLeft: "1rem", width: "90%" }}></div>
              <AppLog />
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default AppLogPage;
