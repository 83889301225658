import { CloudFrameDataTable } from "@cloudframe/data-table";
import { useStyles } from "./license-usage-history.styles";
import { IColumn, SelectionMode } from "@fluentui/react";
import { getFormattedDate } from "../../utils/date.utils";

const mockData = [
  {
    id: "1",
    date: "22-01-2021",
    linesUsed: 120,
    deviceId: "12345",
  },
  {
    id: "1",
    date: "22-02-2022",
    linesUsed: 150,
    deviceId: "12345",
  },
  {
    id: "1",
    date: "01-03-2023",
    linesUsed: 2000,
    deviceId: "12345",
  },
];

const LicenseUsageHistory = (props: any) => {
  const { licenseHistory } = props;
  const classes = useStyles();

  const columns: IColumn[] = [
    {
      key: "id",
      name: "Id",
      fieldName: "id",
      minWidth: 210,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (rowProps) => <p>{rowProps.id}</p>,
    },
    {
      key: "date",
      name: "Date",
      fieldName: "date",
      minWidth: 210,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (rowProps) => <p>{getFormattedDate(rowProps.date)}</p>,
    },
    {
      key: "deviceId",
      name: "Device id",
      fieldName: "deviceId",
      minWidth: 210,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (rowProps) => <p>{rowProps.deviceId}</p>,
    },
    {
      key: "linesUsed",
      name: "Lines used",
      fieldName: "linesUsed",
      minWidth: 210,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (rowProps) => <p>{rowProps.linesUsed}</p>,
    },
  ];

  return (
    <div
      style={{
        padding: "2rem",
        width: "auto",
        overflowX: "hidden",
        flexGrow: 1,
      }}
      className="dashboard-tile"
    >
      <h3 style={{ marginBottom: "2rem" }}>Usage History</h3>
      <div style={{ width: "100%", height: "auto", overflowX: "hidden" }}>
        <CloudFrameDataTable
          items={licenseHistory}
          selectionMode={SelectionMode.none}
          columns={columns}
          className={classes.tableBody}
        />
      </div>
    </div>
  );
};

export default LicenseUsageHistory;
