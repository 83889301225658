import { makeStyles } from "@fluentui/react-components";

export const useListStyles = makeStyles({
  tableBody: {
    width: "auto",
    height: "20rem",
    overflowY: "auto",
    overflowX: "auto",
    display: "block",
    "@media(min-width: 1250px)": {
      overflowX: "auto",
    },
  },
  searchBar: {
    maxWidth: '20%',
    marginTop: '20px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px'
},
});
