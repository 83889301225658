import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CloudFrameIconButton } from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DialogType,
  IColumn,
  IIconProps,
  Icon,
  IconButton,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { IDialogProps as IMessageDialogProps } from "../../@types/form.types";
import { UserPermission } from "../../constants/permissions.constants";
import {
  MUTATION_DATABASE_BACKUP,
  MUTATION_DATABASE_RESTORE,
} from "../../graphql-mutations/database-backup.mutation";
import {
  QUERY_GET_BACKUP_LIST,
  QUERY_GET_DB_LIST,
} from "../../graphql-query/database-backup.query";
import { toISOShortDateStringStrict } from "../../utils/date.utils";
import Authorization from "../auth/authorization";
import ProgressLoader from "../loader/progress-loader";
import { useStyles } from "./database-backup.style";

const DatabaseBackup = () => {
  const [backupDatabase] = useMutation(MUTATION_DATABASE_BACKUP);
  const [restoreDatabase] = useMutation(MUTATION_DATABASE_RESTORE);

  const databaseListQuery = useQuery(QUERY_GET_DB_LIST);
  const [databaseList, setDatabaseList] = useState<Array<any>>([]);
  const [activeDatabase, setActiveDatabase] = useState<any>({});

  const [fetchBackupList] = useLazyQuery(QUERY_GET_BACKUP_LIST);
  const [filteredBackupList, setFilteredBackupList] = useState<Array<any>>([]);
  const [backupList, setBackupList] = useState<Array<any>>([]);
  const [activeBackupItem, setActiveBackupItem] = useState<any>({});

  const [hideRestoreConfirmationDialog, setHideRestoreConfirmationDialog] =
    useState(true);

  const [processInProgress, setProcessInProgress] = useState(false);
  const [messgeInfo, setMessageInfo] = useState<IMessageDialogProps>();
  const [messageHidden, setMessageHidden] = useState(true);
  const [isBackupListOpen, setBackupListOpen] = useState(false);
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");

  const classes = useStyles();
  const contClasses = useContainerStyles();

  const searchIcon: IIconProps = { iconName: "Search" };

  useEffect(() => {
    loadDatabaseList();
  }, [databaseListQuery.data]);

  const loadDatabaseList = async () => {
    await databaseListQuery.refetch();
    let data: Array<any> = databaseListQuery.data?.getDatabaseInfos;
    if (data) {
      const formattedData = data.map((databaseInfo) => {
        return { ...databaseInfo };
      });
      setDatabaseList([...formattedData]);
    }
  };

  const loadBackupList = async (dbId: number) => {
    try {
      const result = await fetchBackupList({
        variables: {
          databaseId: dbId,
        },
      });
      if (result.data && result.data?.getDbBackupListByDatabaseId) {
        setBackupList([...result.data?.getDbBackupListByDatabaseId]);
        setFilteredBackupList([...result.data?.getDbBackupListByDatabaseId]);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const hideConfirmationDialog = () => {
    setHideRestoreConfirmationDialog(true);
  };

  const displayMessage = () => {
    setMessageHidden(false);
    setTimeout(() => {
      setMessageHidden(true);
    }, 2000);
  };

  const onBackupDatabase = async (databaseId: number) => {
    const message: IMessageDialogProps = {
      title: "Database Backup Status",
      message: "Database backup completed successfully",
    };
    setProcessInProgress(true);
    try {
      const result = await backupDatabase({
        variables: {
          id: databaseId,
        },
      });
      if (!result.data || result.data?.backupDatabase.status !== 1) {
        message.message = result.data?.backupDatabase.message;
      }
      setMessageInfo(message);
    } catch (e: any) {
      console.log("Database backup error", e);
    }
    displayMessage();
    setProcessInProgress(false);
  };

  const clearBackupList = () => {
    setActiveBackupItem(null);
    setBackupList([]);
    setFilteredBackupList([]);
  };

  const onRestore = async (dbId: number) => {
    setProcessInProgress(true);
    clearBackupList();
    setBackupListOpen(true);
    try {
      await loadBackupList(dbId);
    } catch (e: any) {
      console.log("Fetch backup list error", e);
    }
    setProcessInProgress(false);
  };

  const onRestoreDatabase = async () => {
    const backupId = activeBackupItem.id;
    const message: IMessageDialogProps = {
      title: "Database Restore Status",
      message: "Database restored successfully using selected backup file",
    };
    setHideRestoreConfirmationDialog(true);
    setProcessInProgress(true);
    try {
      const result = await restoreDatabase({
        variables: {
          backupInfoId: backupId,
        },
      });
      if (result.data && result.data?.restoreDatabase.status === 1) {
        console.log("");
      } else {
        message.message = result.data?.restoreDatabase.message;
      }
    } catch (e: any) {
      console.log("Database restore error", e);
      message.message =
        "There is an issue in the UI. Please check console for more details";
    }
    setMessageInfo(message);
    setProcessInProgress(false);
    displayMessage();
  };

  const databaseListColumns: IColumn[] = [
    {
      key: "databaseName",
      name: "Database Name",
      fieldName: "databaseTitle",
      minWidth: 80,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      // isFiltered: false,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.databaseTitle}</p>,
    },
    {
      key: "databaseHost",
      name: "Instance",
      fieldName: "databaseHost",
      minWidth: 120,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.databaseHost}</p>,
    },
    {
      key: "databaseType",
      name: "Database Type",
      fieldName: "databaseType",
      minWidth: 60,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.databaseType}</p>,
    },
    {
      key: "dbUrl",
      name: "DB URL",
      minWidth: 90,
      maxWidth: 320,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => (
        <p
          title="Click to copy text"
          style={{ cursor: "copy" }}
          onClick={(e: any) => {
            navigator.clipboard.writeText(e.target.innerHTML);
          }}
        >
          {props.connectionString}
        </p>
      ),
    },
    {
      key: "lastArchived",
      name: "Last Archived",
      fieldName: "lastArchived",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.lastArchived}</p>,
    },
    {
      key: "lastRestored",
      name: "Last Restored",
      fieldName: "lastRestored",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.lastRestored}</p>,
    },
    {
      key: "action",
      name: "Action",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      data: "string",
      onRender: (props) => {
        return (
          <p style={{ display: "flex", columnGap: "15px" }}>
            <Authorization>
              <span
                key={UserPermission.P_DATABASE_BACKUP}
                className={contClasses.link}
                onClick={() => onBackupDatabase(props.id)}
              >
                Archive
              </span>
            </Authorization>
            <Authorization>
              <span
                key={UserPermission.P_DATABASE_RESTORE}
                className={contClasses.link}
                onClick={() => onRestore(props.id)}
              >
                Restore
              </span>
            </Authorization>
          </p>
        );
      },
      isPadded: false,
    },
  ];

  const dbBackupListColumns: IColumn[] = [
    {
      key: "sl",
      name: "SL",
      minWidth: 17,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      isPadded: false,
      onRender: (backupInfo, index) => (
        <p>{index ? index + 1 : backupInfo.id}</p>
      ),
    },
    {
      key: "backupDate",
      name: "Backup Date",
      fieldName: "backupDate",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => (
        <p>{toISOShortDateStringStrict(props.backupDate)}</p>
      ),
    },
    {
      key: "backupFile",
      name: "Backup File",
      fieldName: "backupFile",
      minWidth: 200,
      maxWidth: 290,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.backupFile}</p>,
    },
    {
      key: "lastRestoreDate",
      name: "Last Restore Date",
      fieldName: "lastRestoreDate",
      minWidth: 90,
      maxWidth: 160,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => (
        <p>{toISOShortDateStringStrict(props.lastRestoreDate)}</p>
      ),
    },
    {
      key: "restoredBy",
      name: "Last Restored by",
      fieldName: "restoredBy",
      minWidth: 90,
      maxWidth: 264,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.lastRestoredBy}</p>,
      // onRenderHeader: (props) => {
      //   return (
      //     // <CloudFrameDropdown
      //     //   placeholder="Select an option"
      //     //   label="BackupInfo Type"
      //     //   defaultSelectedKey={[""]}
      //     //   onChange={(_e, item: any) => {
      //     //     loadBackupInfosByStatus(item.key);
      //     //   }}
      //     //   options={getBackupInfoStatusOpeions()}
      //     // />

      //   );
      // },
    },
    {
      key: "action",
      name: "Action",
      minWidth: 48,
      maxWidth: 64,
      isResizable: true,
      data: "string",
      onRender: (props) => {
        return (
          <Authorization>
            <CloudFrameIconButton
              title="Restore Database"
              key={UserPermission.P_DATABASE_RESTORE}
              iconProps={{
                iconName: "Archive",
                style: {
                  color: "blue",
                  transform: "scale(1.4)",
                  marginBlock: "5px",
                },
              }}
              onClick={() => {
                setBackupListOpen(false);
                setHideRestoreConfirmationDialog(false);
              }}
            />
          </Authorization>
        );
      },
      isPadded: false,
    },
  ];

  const dialogStyles = { main: { maxWidth: 450 } };

  const modalProps = {
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: undefined,
  };

  const filterDatabaseList = (event: any) => {
    const databaseInfos = databaseListQuery.data?.getDatabaseInfos;
    if (!event.currentTarget.value) {
      setTimeout(() => {
        setDatabaseList(databaseInfos);
      });
    }
    let textFilter = event.currentTarget.value;
    if (textFilter) {
      textFilter = textFilter?.toLowerCase();
      const data = databaseInfos.filter(
        (l: any) =>
          `${l.databaseTitle}`.toLowerCase().includes(textFilter) ||
          `${l.databaseHost}`.toLowerCase().includes(textFilter) ||
          `${l.databasePort}`.toLowerCase().includes(textFilter) ||
          `${l.databaseName}`.toLowerCase().includes(textFilter) ||
          `${l.databaseType}`.toLowerCase().includes(textFilter) ||
          `${l.lastArchived}`.toLowerCase().includes(textFilter) ||
          `${l.lastRestored}`.toLowerCase().includes(textFilter)
      );
      setDatabaseList(data);
    } else {
      setDatabaseList([...databaseInfos]);
    }
  };

  const filterDatabaseBackupList = (event: any) => {
    const backupInfos = { ...backupList };
    if (!event.currentTarget.value) {
      setTimeout(() => {
        setBackupList(backupInfos);
      });
    }
    let textFilter = event.currentTarget.value;
    if (textFilter) {
      textFilter = textFilter?.toLowerCase();
      const data = backupInfos.filter(
        (l: any) =>
          `${toISOShortDateStringStrict(l.backupDate)}`
            .toLowerCase()
            .includes(textFilter) ||
          `${l.backupFile}`.toLowerCase().includes(textFilter) ||
          `${toISOShortDateStringStrict(l.lastRestoreDate)}`
            .toLowerCase()
            .includes(textFilter) ||
          `${l.restoredBy}`.toLowerCase().includes(textFilter)
      );
      setBackupList(data);
    } else {
      setBackupList([...backupInfos]);
    }
  };

  return (
    <div style={{ marginTop: "3rem" }}>
      <ProgressLoader show={processInProgress}></ProgressLoader>
      <h3>Backup Data</h3>
      <hr />
      <div className={contClasses.flexStartContainer}>
        <CloudFrameTextBox
          placeholder="Search"
          iconProps={searchIcon}
          style={{ width: "20rem" }}
          onChange={(e) => filterDatabaseList(e)}
        />
        {/* <CloudFramePrimaryButton
          style={{ padding: "1rem" }}
          title="Refresh List"
          iconProps={{ iconName: "refresh" }}
          onClick={() => {
            loadBackupList();
          }}
        /> */}
      </div>
      <div style={{ width: "auto", marginTop: "1rem" }}>
        <CloudFrameDataTable
          items={databaseList || []}
          selectionMode={SelectionMode.none}
          columns={databaseListColumns}
          className={classes.tableBody}
          onActiveItemChanged={(item, index) => setActiveDatabase({ ...item })}
        />
      </div>
      <CloudFrameDialog
        hidden={hideRestoreConfirmationDialog}
        onDismiss={hideConfirmationDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Confirm Database Restore",
          closeButtonAriaLabel: "Close",
          subText:
            "Are you sure you want to restore the database? This may result in data loss. Please take necessary backup before restoring database.",
        }}
        modalProps={modalProps}
        primaryButton={{
          text: "Restore",
          style: { backgroundColor: "red" },
          onClick: onRestoreDatabase,
        }}
        secondaryButton={{ text: "Cancel", onClick: hideConfirmationDialog }}
      />
      <CloudFrameDialog
        hidden={messageHidden}
        onDismiss={() => setMessageHidden(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: messgeInfo?.title,
          closeButtonAriaLabel: "Close",
          subText: messgeInfo?.message,
        }}
        modalProps={modalProps}
        primaryButton={{ text: "Close", onClick: () => setMessageHidden(true) }}
      />
      <Modal
        titleAriaId={"dbRestoreModal"}
        isOpen={isBackupListOpen}
        onDismiss={() => setBackupListOpen(false)}
        isModeless={false}
        isBlocking={true}
        dragOptions={undefined}
        styles={{
          main: { borderRadius: 20, overflowY: "hidden", position: "fixed" },
        }}
      >
        <div style={{ width: "64rem", padding: "2rem" }}>
          <div className={contClasses.flexSpaceBtwnContainer}>
            <Icon iconName="TextDocument" style={{ fontSize: "2rem" }} />
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "1rem" }}>
                <CloudFrameTextBox
                  placeholder="Search"
                  iconProps={searchIcon}
                  style={{ width: "20rem" }}
                  onChange={(e) => filterDatabaseBackupList(e)}
                />
              </div>
              <IconButton
                iconProps={{
                  iconName: "Cancel",
                  style: { fontSize: "1.3rem" },
                }}
                ariaLabel="Close popup modal"
                onClick={() => setBackupListOpen(false)}
              />
            </div>
          </div>
          <div id="dbArchiveList">
            <h3>Database Archive List</h3>
            <hr />
            <div>
              <div style={{ width: "auto", marginTop: "1rem" }}>
                <CloudFrameDataTable
                  setKey="items"
                  items={filteredBackupList}
                  columns={dbBackupListColumns}
                  selectionMode={SelectionMode.none}
                  // enableShimmer={processInProgress}
                  // ariaLabelForShimmer="Loading..."
                  ariaLabelForGrid="Database Archive List"
                  listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                  }}
                  onActiveItemChanged={(item, index) =>
                    setActiveBackupItem({ ...item })
                  }
                />
                {(!filteredBackupList || filteredBackupList.length < 1) && (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    No data found
                  </div>
                )}
                <br />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DatabaseBackup;
