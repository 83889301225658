import React from 'react';
import {forbiddenContainer} from './forbidden-page.style'
function ForbiddenPage() {
  return (
    <div className={forbiddenContainer().main}>
      <h3 className={forbiddenContainer().title}>You are not permitted to view this.</h3>
      <p className={forbiddenContainer().message}>Please contact the admin for assistance.</p>
    </div>
  );
}

export default ForbiddenPage;
