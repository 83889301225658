import "./styles.css";

export const Avatar = ({text, color}: any) => {
  return (
    <p
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "2.5rem",
        width: "2.5rem",
        backgroundColor: color,
        borderRadius: "50%",
        fontSize: "1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        margin: 0,
        marginRight: ".5rem"
      }}
    >
      {text}
    </p>
  );
};

const ProductComponent = (props: any) => {
  const { name, selected,avatar, ...rest } = props;
  return (
    <button className={`button ${selected ? "button-selected" : ""}`} {...rest}>
      <div className="button-header">
        <Avatar text={avatar?.initial} color={avatar?.color} />
        <strong>{name}</strong>
      </div>
      <div className="button-content">
        <div className="label">Sales</div>
        <div className="price">$2,794.00</div>
      </div>
    </button>
  );
};

export default ProductComponent;
