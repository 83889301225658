import { useMemo } from "react";
import NeedlePieChart from "../../../components/needle-pie-chart/needle-pie-chart";
import { ILicenseExpiryOverview } from "./license-usage-overview.interface";

const needleChartData = [
  { name: "A", value: 50, color: "#00ff00" },
  { name: "B", value: 25, color: "#0000ff" },
  { name: "C", value: 25, color: "#ff0000" },
];

const LicenseExpiryOverview = (props: ILicenseExpiryOverview) => {
  const { customerName, licenseData } = props;

  const usagePercent = useMemo(() => {
    if (licenseData.licenseHistory) {
      const usedLines = licenseData.licenseHistory.reduce(
        (acc, obj) => acc + obj.linesUsed,
        0
      );
      const totalLines = licenseData.linesOfCode;
      const percent = (usedLines / totalLines) * 100;

      return percent > 100 ? 100 : percent;
    }
    return 0;
  }, [licenseData]);

  return (
    <div className="dashboard-tile" style={{ minWidth: "25rem", padding: 0, }}>
      <h3
        style={{
          margin: 0,
          padding: "1.3rem",
          borderBottom: "1px solid #eee",
          color: "black",
        }}
      >
        {"License expiry overview"}
      </h3>
      <div style={{ padding: "1rem" }}>
        <div style={{ height: "16rem" }}>
          <NeedlePieChart
            data={needleChartData}
            value={usagePercent}
          />
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            borderTop: "1px solid #eee",
            borderRight: "1px solid #eee",
            width: "100%",
          }}
        >
          <div
            style={{
              padding: ".5rem",
              marginLeft: "1rem",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <h3 style={{ margin: 0, color: "grey", marginTop: "0" }}>
              <a style={{ textDecoration: "none", color: "rgb(85,26,139)" }}>
                {customerName ? "Notify" : "Renew license"}
                <span style={{ fontSize: "2rem" }}>&rarr;</span>
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseExpiryOverview;
