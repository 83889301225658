import {
  CacheLookupPolicy,
  Configuration,
  InteractionRequiredAuthError,
  PopupRequest,
  IPublicClientApplication,
  AuthenticationResult,
  SilentRequest,
} from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "9c0558a0-aaf1-4ac7-9688-647977cb1792",
    authority:
      "https://login.microsoftonline.com/6f6cf8a8-56d6-4a22-a8cc-27cc765e990a",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};

export const acquireTokenSilently = async (
  pca: IPublicClientApplication
): Promise<AuthenticationResult | null> => {
  let activeAccount = pca.getActiveAccount();
  let username = activeAccount ? activeAccount.username : null;
  if (username) {
    let currentAccount = pca.getAccountByUsername(username) || undefined;
    let silentRequest: SilentRequest = {
      scopes: ["User.Read"],
      account: currentAccount,
      forceRefresh: false,
      cacheLookupPolicy: CacheLookupPolicy.Default, // will default to CacheLookupPolicy.Default if omitted
    };

    let request = {
      scopes: ["User.Read"],
      loginHint: currentAccount ? currentAccount.username : null, // For v1 endpoints, use upn from idToken claims
    };

    // @ts-ignore
    const tokenResponse: AuthenticationResult = await pca
      .acquireTokenSilent(silentRequest)
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          // @ts-ignore
          return pca.acquireTokenRedirect(request);
        }
      });
    // @ts-ignore
    // sessionStorage.setItem("token", tokenResponse?.idToken);
    return tokenResponse;
  } else {
    return new Promise((resolve) => {
      resolve(null);
    });
  }
};