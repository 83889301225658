import { initializeIcons, Modal, Rating, RatingSize } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { QUERY_GET_LICENSE_WITH_HISTORY } from "../../graphql-query/license.query";
import useStyles from "./dashboard-client.styles";
import LicenseUsageHistory from "../../components/license-usage-history/license-usage-hsitory";
import { useLocation } from "react-router-dom";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import WelcomeTile from "./welcome-tile/welcome-tile";
import LicenseUsageOverview from "./license-usage-overview/license-usage-overview";
import LicenseExpiryOverview from "./license-expiry-overview/license-expiry-overview";
import LicenseDetail from "./license-detail/license-detail";
import { ILicenseData } from "./dashboard-client.interface";
import RenovateUpSell from "./renovate-up-sell/renovate-up-sell";
import Authorization from "../../components/auth/authorization";
import { UserPermission } from "../../constants/permissions.constants";

const DashboardClient = () => {
  const [fetchLicenseDetails, licenseDetails] = useLazyQuery(
    QUERY_GET_LICENSE_WITH_HISTORY
  );
  const [licenseData, setLicenseData] = useState<ILicenseData>(
    {} as ILicenseData
  );
  const [showModal, setShowModal] = useState(false);

  const { state } = useLocation();
  const routeParams = state || {};
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const result = await fetchLicenseDetails({
        variables: { licenseId: state?.licenseId || 1 }, // default value should be removed
      });
      setLicenseData((result.data?.getLicense as ILicenseData) || {});
    })();
  }, [state]);

  initializeIcons();

  if (licenseDetails.loading) {
    return <p>Loading...</p>;
  }
  if (licenseDetails.error) {
    console.log(licenseDetails.error);
    return <p>Unable to fetch the license status !!!</p>;
  }

  return (
    <section className={classes.root}>
      <Authorization>
        <WelcomeTile
          customerName={routeParams.customerName}
          onClickShowModal={() => setShowModal(true)}
        />

        <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          <LicenseUsageOverview licenseData={licenseData} />
          <LicenseExpiryOverview
            customerName={routeParams.customerName}
            licenseData={licenseData}
          />
          <LicenseDetail
            customerName={routeParams.customerName}
            licenseData={licenseData}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            marginTop: "1rem",
            flexWrap: "wrap",
          }}
        >
          <LicenseUsageHistory
            licenseHistory={licenseData?.licenseHistory || []}
          />
          {!routeParams.customerName && <RenovateUpSell />}
        </div>
        <Modal
          titleAriaId={"titleId"}
          isOpen={showModal}
          onDismiss={() => setShowModal(false)}
          isModeless={true}
          isBlocking={false}
          // containerClassName={contentStyles.container}
          dragOptions={undefined}
          styles={{ main: { borderRadius: 20 } }}
        >
          <div style={{ width: "30rem", padding: "1rem" }}>
            <h3
              style={{
                margin: 0,
                padding: "1.3rem",
                borderBottom: "1px solid #eee",
                color: "black",
              }}
            >
              Customer contact details
            </h3>
            <div style={{ padding: "1rem" }}>
              <div style={{ height: "16rem" }}>
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    color: "grey",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      style={{ borderBottom: "1px solid #eee", padding: "4px" }}
                    >
                      Id:
                    </h3>
                    <h3
                      style={{ borderBottom: "1px solid #eee", padding: "4px" }}
                    >
                      Name:
                    </h3>
                    <h3
                      style={{ borderBottom: "1px solid #eee", padding: "4px" }}
                    >
                      Email:
                    </h3>
                    <h3
                      style={{ borderBottom: "0px solid #eee", padding: "4px" }}
                    >
                      Phone:
                    </h3>
                  </div>
                  <div
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      style={{
                        borderBottom: "1px solid #eee",
                        padding: "4px",
                        borderRadius: 20,
                      }}
                    >
                      {licenseData.id}
                    </h3>
                    <h3
                      style={{ borderBottom: "1px solid #eee", padding: "4px" }}
                    >
                      {routeParams.customerName}
                    </h3>

                    <h3
                      style={{ borderBottom: "1px solid #eee", padding: "4px" }}
                    >
                      {licenseData.email}
                    </h3>
                    <h3
                      style={{ borderBottom: "0px solid #eee", padding: "4px" }}
                    >
                      123123123
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    padding: ".5rem",
                    marginLeft: "1rem",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <h3 style={{ margin: 0, color: "grey", marginTop: "0" }}>
                    <CloudFramePrimaryButton
                      text="Ok"
                      onClick={() => setShowModal(false)}
                    />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Authorization>
    </section>
  );
};

export default DashboardClient;
