import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { FontIcon, initializeIcons } from "@fluentui/react";
import { FormEvent, useState } from "react";
import FacebookSVG from "../../icons/svg/facebook";
import InstagramSVG from "../../icons/svg/instagram";
import LinkedInSVG from "../../icons/svg/linkedin";
import TwitterSVG from "../../icons/svg/twitter";
import YoutubeSVG from "../../icons/svg/youtube";
import { useStyles } from "./contact-us.styles";
import Authorization from "../../components/auth/authorization";
import { UserPermission } from "../../constants/permissions.constants";

const ContactUsPage = () => {
  const [subject, setSubject] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log("FORM VALUES", { subject, phone, message });
  };

  const classes = useStyles();
  initializeIcons();
  return (
    <Authorization>
      <section
        key={UserPermission.P_CONTACT_US}
        style={{ padding: "2rem", paddingLeft: "3rem", paddingRight: "2rem" }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            margin: "1rem",
            marginLeft: "0rem",
            marginRight: "1rem",
            marginTop: "0rem",
            marginBottom: 0,
            //boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
            // padding: "1rem",
            // //width: "100%",
            // paddingLeft: "2rem",
            // paddingRight: "2rem",
            padding: 0,
            maxWidth: "60rem",
          }}
          className="dashboard-tile "
        >
          <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            <div
              style={{
                margin: "2rem",
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
                flex: 2,
                padding: "2rem",
              }}
            >
              <div style={{ paddingLeft: "1rem", width: "75%" }}></div>
              <h3>Contact us</h3>
              <p style={{ color: "grey", marginTop: "1rem" }}>
                We would love to hear from you
              </p>

              <form onSubmit={onFormSubmit}>
                <CloudFrameTextBox
                  onChange={(_, value = "") => {
                    setSubject(value);
                  }}
                  required
                  label="Subject"
                  className={classes.mb8}
                  value={subject}
                  disabled={false}
                />
                <CloudFrameTextBox
                  onChange={(_, value = "") => {
                    setPhone(value);
                  }}
                  label="Phone"
                  className={classes.mb8}
                  value={phone}
                  disabled={false}
                  pattern="^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                />

                <CloudFrameTextBox
                  onChange={(_, value = "") => {
                    setMessage(value);
                  }}
                  required
                  label="Message"
                  className={classes.mb8}
                  value={message}
                  disabled={false}
                  multiline
                  rows={10}
                />
                <CloudFramePrimaryButton
                  text="Send now"
                  type="submit"
                  style={{ marginTop: "1rem" }}
                />
              </form>
            </div>
            <div
              style={{
                width: "100%",
                height: "auto",
                color: "#fff",
                flex: 1,
                flexGrow: 1,
                backgroundColor: "rgba(0, 120, 212,1)",
                padding: "2rem",
                borderRadius: 20,
              }}
            >
              <h3>Contact Info</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "75%",
                }}
              >
                <p
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <FontIcon
                    aria-label="Compass"
                    iconName="Globe"
                    style={{
                      fontSize: 30,

                      color: "#fff",
                    }}
                  />{" "}
                  123 lorem ispum Abc, dolar amet.
                </p>
                <p
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <FontIcon
                    aria-label="Compass"
                    iconName="Mail"
                    style={{
                      fontSize: 30,

                      color: "#fff",
                    }}
                  />{" "}
                  example@test
                </p>
                <p
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <FontIcon
                    aria-label="Compass"
                    iconName="Phone"
                    style={{
                      fontSize: 30,

                      color: "#fff",
                    }}
                  />{" "}
                  1-234-567-890 <br /> 1-634-537-190
                </p>
              </div>
              <div style={{ marginTop: "4rem" }}>
                <h3>Follow on:</h3>
                <div
                  style={{ display: "flex", gap: "1rem", marginTop: "2rem" }}
                >
                  <div style={{ cursor: "pointer" }}>
                    <FacebookSVG />
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <InstagramSVG />
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <YoutubeSVG />
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <LinkedInSVG />
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <TwitterSVG />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default ContactUsPage;
