import { makeStyles } from "@fluentui/react-components"

export const inviteForm = makeStyles({
    main: {
        width: '100%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderBottomWidth: '0.2px',
        borderBottomColor: 'lightgray',
        borderBottomStyle: 'solid',
        marginTop: "1rem"
    }
})

export const formHeader = makeStyles({
    main: {
        minHeight: '20px',
        lineHeight: '20px',
        // paddingBlock: '3px',
        marginTop: '10px',
        verticalAlign: 'middle',
    },
    heading: {
        marginLeft: '20px',
        color: 'black',
    }
})

export const formBody = makeStyles({
    main: {
        // marginBlock: '20px',
        width: '50%'
    },
    formGroup1: {
        // paddingBlock: '5px',
        width: '75%'
    },
    formGroup2: {
        // paddingBlock: '5px',
    },
    accessLabel: {
        fontWeight: '600'
    },
    radiobuttons: {
        fontSize: 'relative-size',
        display: 'flex',
        flexDirection: 'row'
    }
})
export const formFooter = makeStyles({
    main: {
        borderTopWidth: '1px',
        borderTopColor: 'gray',
        borderTopStyle: 'solid',
        width: '50%'
    },
    primaryButton: {
        // marginBlock: '10px',
    },
    secondaryButton: {
        // marginBlock: '10px',
    },
    inviteMessage : {
        color: 'darkgreen',
        fontWeight: '625'
    }
})

export const userTable = makeStyles({
    userIcon: {
        transform: 'scale(1.5)',
        // marginBlock: '5px'
    },
    editAction: {
        transform: 'scale(1.5)',
        color: 'blue'
    },
    revokeAction: {
        transform: 'scale(1.5)',
        color: 'red'
    },
    searchBar: {
        maxWidth: '20%',
        marginTop: '20px',

        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px'
    },
    tableBody: {
        width: 'auto',
        height: '240px',
        overflowY: 'auto',
        backgroundColor: "#fff",
        display:'block',
        "@media(min-width: 1250px)": {
            overflowX: 'hidden',
        }
    }
})