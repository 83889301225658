import React, { ReactElement, ReactNode } from "react";
import { useSelector } from "react-redux";
import { UserInfo } from "../../@types/auth.types";
import ForbiddenPage from "./forbidden-page";
import { AuthorizationStatus } from "../../constants/auth.constants";
import Loader from "../loader/loader";

interface AuthorizationProps {
  children: ReactNode;
}

const checkPermission = (
  section: ReactElement,
  user: UserInfo
): AuthorizationStatus => {
  if (section && section.key) {
    const isPermitted =
      section.key &&
      (user.roles.includes("Admin") ||
        user.permissions.includes(section.key?.toString()));
    return isPermitted
      ? AuthorizationStatus.AUTHORIZED
      : AuthorizationStatus.FORBIDDEN;
  }
  return AuthorizationStatus.UNAUTHORIZABLE;
};

const getPermittedChildren = (
  children: ReactElement[],
  user: UserInfo
): ReactElement => {
  let isButton = false;
  const permittedComponents = children.filter((section, index) => {
    const isPermitted =
      checkPermission(section, user) === AuthorizationStatus.AUTHORIZED;
    isButton = isPermitted && typeof section?.type === "function";
    return isPermitted;
  });
  return permittedComponents?.length > 0 ? (
    <>{permittedComponents}</>
  ) : isButton ? (
    <p>...</p>
  ) : (
    <ForbiddenPage />
  );
};

const getPermittedChild = (
  child: ReactElement,
  user: UserInfo
): ReactElement | null => {
  const status = checkPermission(child, user);
  if (status === AuthorizationStatus.AUTHORIZED) {
    return child;
  } else if (status === AuthorizationStatus.FORBIDDEN) {
    return <ForbiddenPage />;
  } else {
    return null;
  }
};

const Authorization: React.FC<AuthorizationProps> = ({
  children,
}: AuthorizationProps): ReactElement | null => {
  const user = useSelector((state: UserInfo) => state);

  if (!user.username) {
    return <Loader></Loader>;
  } else if (Array.isArray(children)) {
    return getPermittedChildren(children, user);
  } else {
    return getPermittedChild(children as ReactElement, user);
  }
};

export default Authorization;
