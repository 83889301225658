import React, {  useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";

const example = [
  {
    name: "Used Lines",
    value: 600,
    fill: "rgba(250,0,0,1)",
    centerTextFill: "rgba(200,40,40,1)",
  },
  {
    name: "Unused Lines",
    value: 400,
    fill: "rgba(0,250,0,1)",
    centerTextFill: "rgba(25,150,40,1)",
  },
];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  const percentText = `( ${(percent * 100).toFixed(2)}%)`;

  return (
    <g>
      <text
        x={cx}
        y={cy - 10}
        dy={8}
        textAnchor="middle"
        fill={payload.centerTextFill}
      >
        {payload.name}
      </text>
      <text
        fontSize={20}
        fontWeight="bold"
        x={cx}
        y={cy + 15}
        dy={8}
        textAnchor="middle"
        fill={payload.centerTextFill}
      >
        {percentText}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`Total ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {percentText}
      </text>
    </g>
  );
};

const ActiveShapePieChart = (props: any) => {
  const {
    defaultActiveIndex,
    data,
    width = 400,
    height = 400,
    innerRadius = 60,
    outerRadius = 80,
  } = props;
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex || 0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={width} height={height}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ActiveShapePieChart;
