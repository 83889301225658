import { UserInfo } from "../@types/auth.types";

export const currentUserInfo: UserInfo = {
  username: "",
  roles: [],
  permissions: [],
  loader: true,
};

export enum AuthorizationStatus {
  AUTHORIZED = "AUTHORIZED",
  FORBIDDEN = "FORBIDDEN",
  UNAUTHORIZABLE = "UNAUTHORIZABLE",
}
