import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const example = [
  {
    name: "Jan",
    Active: 100,
    Renewed: 20,
    Expired: 20,
  },
  {
    name: "Feb",
    Active: 120,
    Renewed: 21,
    Expired: 15,
  },
  {
    name: "Mar",
    Active: 110,
    Renewed: 5,
    Expired: 5,
  },
  {
    name: "Apr",
    Active: 50,
    Renewed: 30,
    Expired: 20,
  },
  {
    name: "May",
    Active: 130,
    Renewed: 25,
    Expired: 50,
  },
  {
    name: "Jun",
    Active: 100,
    Renewed: 5,
    Expired: 1,
  },
  {
    name: "Jul",
    Active: 111,
    Renewed: 11,
    Expired: 9,
  },
];

const LineChartComponent = (props: any) => {
  const {data} = props;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="Embedded Licenses" stroke="#82ca9d" />
        <Line type="monotone" dataKey="Subscriptions" stroke="#FFBB28" />
        <Line
          type="monotone"
          dataKey="Renewed"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="Expired" stroke="rgba(300,0,0,1)" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
