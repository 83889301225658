import { makeStyles } from "@fluentui/react-components";

export const useContainerStyles = makeStyles({
  root: {
    paddingLeft: "1.5rem",
    "@media(max-width: 700px)": {
      paddingLeft: 0,
    },
  },
  sectionContainer: {
    paddingTop: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  mt16: {
    marginTop: "1rem",
  },
  ml2: {
    marginLeft: "2rem",
  },
  mb8: {
    marginBottom: "1rem",
  },
  subContainer: {
    paddingRight: "25rem",
    "@media(max-width: 1018px)": {
      paddingRight: "12rem",
    },
    "@media(max-width: 700px)": {
      paddingLeft: "1rem",
      paddingRight: "0rem",
    },
  },
  boxContainer: {
    backgroundColor: "#fff",
    marginLeft: "0rem",
    marginRight: "1rem",
    marginTop: "0rem",
    marginBottom: 0,
    //boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
    //width: "100%",
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  flexEndContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
    display: "flex",
    columnGap: "10px",
    rowGap: "10px",
    justifyContent: "end",
    flexWrap: "wrap",
  },
  flexStartContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
    display: "flex",
    columnGap: "10px",
    rowGap: "10px",
    justifyContent: "start",
    flexWrap: "wrap",
  },
  flexSpaceBtwnContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
    display: "flex",
    columnGap: "10px",
    rowGap: "10px",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  containerTitle: {
    paddingLeft: '2rem'
  },
  link: {
    color: 'blue',
    cursor: 'pointer'
  }
});
