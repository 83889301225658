import { UserInfo } from "../../@types/auth.types";

const initialState: UserInfo = {
  username: "",
  roles: [],
  permissions: [],
  loader: true
};

const userReducer = (
  state: UserInfo = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
