import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_VALIDATE_TOKEN } from "../graphql-query/user.query";

const AcceptInvitation = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, data, error } = useQuery(QUERY_VALIDATE_TOKEN, {
    variables: { token: searchParams.get("inv") },
    errorPolicy: "all",
  });

  const welcomeUser = (email: string) => {
    setMessage(
      `Welcome, your email ${email} validated successfully.`
    );
    setTimeout(() => {
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 0);
    }, 3000);
  };

  useEffect(() => {
    if (data && data.validateInvitationsToken) {
      if (data.validateInvitationsToken.userEmail.trim().length < 1) {
        setMessage("Invalid request!");
      } else if (data.validateInvitationsToken.userEmail.trim().length > 3) {
        welcomeUser(data?.validateInvitationsToken?.userEmail);
      }
    } else {
      const errors = { ...error };
      if (errors.graphQLErrors) {
        const gpErrors = errors.graphQLErrors as Array<any>;
        if (gpErrors.length) {
          const message: string = gpErrors[0]?.message;
          if (
            message.startsWith(
              "QueryFailedError: duplicate key value violates unique constraint"
            )
          ) {
            welcomeUser("already have been");
          }
        }
      }
    }
  }, [data]);

  return (
    <div
      id={"accept-invitation-div"}
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading && <span>Activating user...</span>}
      {message.trim().length < 1 && !loading ? (
        <>
          <button
            onClick={() => {
              window.location.reload();
            }}
          >
            Accept invitation
          </button>
        </>
      ) : (
        <div>
          <h3>{message}</h3>
        </div>
      )}
    </div>
  );
};

export default AcceptInvitation;
