import { gql } from "@apollo/client";

export const QUERY_GET_USERS = gql`
  query GetUsers {
    getUsers {
      id
      name
      userName
      roles {
        name
      }
      email
      isApproved
    }
  }
`;

export const QUERY_GET_USER_LIST = gql`
  query GetUserList {
    getUserList {
      activationDate
      email
      name
      invitationDate
      roles
    }
  }
`;

export const QUERY_GET_USER = gql`
  query GetUser($getUserId: Float!) {
    getUser(id: $getUserId) {
      name
      email
      userName
      roles {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_INVITED_USERS = gql`
  query GetInvitations {
    getInvitations {
      userEmail
      validated
      role
      id
      codeGenerationTime
    }
  }
`;

export const MUTATION_INVITE_USER = gql`
  mutation InviteUser($role: String!, $email: String!) {
    inviteUser(role: $role, email: $email) {
      userEmail
      validated
      verificationCode
      role
      id
      codeGenerationTime
    }
  }
`;

export const MUTATION_EDIT_USER = gql`
  mutation PersistUserInfo(
    $id: Float!
    $roleIds: String!
    $email: String!
    $userName: String!
    $name: String!
  ) {
    persistUserInfo(
      id: $id
      roleIds: $roleIds
      email: $email
      userName: $userName
      name: $name
    ) {
      operationStatusMessage
      operationStatus
    }
  }
`;

export const QUERY_VALIDATE_TOKEN = gql`
  query Query($token: String!) {
    validateInvitationsToken(token: $token) {
      userEmail
    }
  }
`;
