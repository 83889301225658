import { CloudFrameStack } from "@cloudframe/stack";
import LicenseForm from "../components/license-form/license-form";
import Authorization from "../components/auth/authorization";
import { UserPermission } from "../constants/permissions.constants";

const CreateLicensePage = () => {
  return (
    <Authorization>
      <section key={UserPermission.P_CREATE_NEW_LICENSE}>
        <div style={{ margin: 16 }}>
          <CloudFrameStack>
            <LicenseForm />
          </CloudFrameStack>
        </div>
      </section>
    </Authorization>
  );
};

export default CreateLicensePage;
