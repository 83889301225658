import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    // paddingBlock: "1rem",
    paddingTop: "1rem",
    paddingRight: "1rem",
    display: "flex",
    flexDirection: "row-reverse",
    //justifyContent: "space-around",

    "@media(max-width: 1700px)": {
      flexDirection: "column",
    },
  },
  productSelectRoot: {
    paddingBottom: "3rem",
    paddingRight: "3rem",
    paddingLeft: "3rem",
    paddingTop: "1rem",
    backgroundColor: "#fff",
    display: "inline-block",
    height: "max-content",
    marginTop: "0rem",
    minWidth: "11%",
    maxWidth: "87%",
    "@media(max-width: 1700px)": {
      marginBottom: "1rem",
      marginTop: "-.5rem",
      marginLeft: "2%", 
      paddingBottom: "3rem",
      paddingRight: "3rem",
      paddingLeft: "3rem",
      paddingTop: "1rem",
    },
  },
  productSelectContainer: {
    display: "flex",
    rowGap: "1rem",
    flexDirection: "column",
    "@media(max-width: 1700px)": {
      flexDirection: "row",
      columnGap: "1rem",
      paddingLeft: "1rem",
    },
  },
  icon: {
    color: "green",
    transform: "scale(1.7)",
    // marginBlock: "5px",
    marginRight: "1.5rem",
    backgroundColor: "rgba(100,300,100,.5)",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "10px",
    paddingLeft: "10px",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    // "@media(max-width: 1080px)": {
    //     transform: "scale(1.2)",
    //     marginRight: "0rem"
    // },
  },
  "lastIcon":{
     "@media(max-width: 1332px)": {
        marginLeft: 0
    },
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    flexWrap: "wrap"
  }
});

export default useStyles;
