import { gql } from "@apollo/client";

export const QUERY_GET_PERMISSIONS = gql`
  query GetPermissions {
    getPermissions {
      id
      name
      componentKey
      roles {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_USER_ROLE_PERMISSIONS = gql`
  query GetPermissionsByUsername($username: String!) {
    getPermissionsByUsername(username: $username) {
      username
      roles
      permissions
    }
  }
`;
