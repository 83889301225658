import { useQuery } from "@apollo/client";
import { IPublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDefaultHeader } from "@cloudframe/header";
import { IHeaderConfig } from "@cloudframe/header/dist/cjs/src/@type/header.types";
import { DefaultPageFooterComponent } from "@cloudframe/page-footer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { softLogoutUser } from ".";
import "./App.css";
import { loginRequest } from "./auth-config";
import InviteForm from "./components/invite-form/invite-form";
import RegistrationKeyForm from "./components/registration-key-form/registration-key-form";
import { SideNav } from "./components/sidenav/sidenav";
import UserList from "./components/user-list/user-list";
import { QUERY_GET_USER_ROLE_PERMISSIONS } from "./graphql-query/permission.query";
import AcceptInvitation from "./pages/accept-invitation";
import AllLicensesPage from "./pages/all-licenses";
import AppLogPage from "./pages/app-logs/app-log.page";
import LicenseAuditTrailPage from "./pages/audit-trail/license-audit-trail.page";
import ContactUsPage from "./pages/contact-us/contact-us";
import CreateLicensePage from "./pages/create-license";
import DashboardClient from "./pages/dashboard-client/dashboard-client";
import Dashboard from "./pages/dashboard/dashboard";
import EditLicensePage from "./pages/edit-license";
import Home from "./pages/home";
import RolesPage from "./pages/user-role/user-role";
import { setUser } from "./redux/auth/action";
import DatabaseBackupRestorePage from "./pages/database-backup-restore";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const dispatch = useDispatch();
  const { data, error, loading } = useQuery(QUERY_GET_USER_ROLE_PERMISSIONS, {
    variables: { username: pca.getActiveAccount()?.username ?? "" },
    errorPolicy: "all",
  });
  const [permissionErrorCount, setPermissionErrorCount] = useState(0);

  const isLoggedIn = window.sessionStorage.getItem("cfa");

  useEffect(() => {
    getUserPermissions();
  }, [pca, loading, error]);

  const getUserPermissions = async () => {
    // console.log("error", (error?.networkError as any)?.result?.errors[0]?.message);
    const userData = data?.getPermissionsByUsername;
    if (userData) {
      dispatch(setUser({ ...userData }));
    } else {
      if (permissionErrorCount > 10) {
        softLogoutUser();
      } else {
        setPermissionErrorCount(permissionErrorCount + 1);
      }
    }
  };

  const handleLogin = async () => {
    await pca.loginRedirect(loginRequest);
  };
  const headerConfig = [
    // {
    //   key: "home",
    //   title: "home",
    //   url: "/",
    //   icon: "home",
    // },
    // {
    //   key: "dashboard",
    //   title: "dashboard",
    //   url: "/dashboard",
    //   icon: "ViewDashboard",
    // },
    // {
    //   key: "user-role",
    //   title: "user-role",
    //   url: "/user-role",
    //   icon: "WorkforceManagement",
    // },
    // {
    //   key: "profile",
    //   title: "profile",
    //   icon: "Contact",
    //   url: "#",
    //   items: [
    //     {
    //       key: "logout",
    //       title: "logout",
    //       url: "/logout",
    //       icon: "SignOut",
    //       iconStyle: {
    //         transform: "scale(1.1)",
    //       },
    //       onClick: () => {
    //         // pca.logout(loginRequest).then(() => {
    //         //   console.log("Logout!");
    //         // });
    //       },
    //     },
    //   ],
    //   iconStyle: {
    //     background: "#fff",
    //     color: "blue",
    //     borderRadius: "50%",
    //     transform: "scale(1.15)",
    //     marginRight: ".5rem",
    //   },
    // },
    {
      key: "logout",
      title: "logout",
      url: "/logout",
      icon: isLoggedIn ? "SignOut" : undefined,
      iconStyle: {
        transform: "scale(1.1)",
      },
      onClick: () => {
        // pca.logout(loginRequest).then(() => {})
        window.sessionStorage.clear();
        window.location.href = window.location.origin;
      },
    },
  ] as Array<IHeaderConfig>;

  return (
    <div className="wrapper">
      <MsalProvider instance={pca}>
        <BrowserRouter>
          <header>
            <CloudFrameDefaultHeader
              title="Customerry"
              headerConfig={headerConfig}
              linkComponent={Link}
            />
          </header>
          <AuthenticatedTemplate>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: 4,
                height: "85vh",
                overflow: "auto",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  height: "90%",
                  margin: "1rem",
                  marginLeft: ".5rem",
                  marginRight: ".5rem",
                  padding: ".5rem",
                }}
                className="dashboard-tile "
              >
                <SideNav />
              </div>
              <div
                style={{
                  flexGrow: 1,
                  height: "99%",
                  borderRadius: "5px",
                }}
              >
                <Routes>
                  <Route path={""} element={<Home />} />
                  <Route path={"/"} element={<Home />} />
                  <Route path={"/dashboard"} element={<Dashboard />} />
                  <Route path={"/contact-us"} element={<ContactUsPage />} />
                  <Route
                    path={"/dashboard-client"}
                    element={<DashboardClient />}
                  />
                  <Route path={"/edit-license"} element={<EditLicensePage />} />
                  <Route
                    path={"/create-license"}
                    element={<CreateLicensePage />}
                  />
                  <Route path={"/user-role"} element={<RolesPage />} />
                  <Route path={"/all-licenses"} element={<AllLicensesPage />} />
                  <Route
                    path={"/activation-key"}
                    element={<RegistrationKeyForm />}
                  />
                  <Route
                    path={"/accept-invitation"}
                    element={<AcceptInvitation />}
                  ></Route>
                  <Route path={"/invite-user"} element={<InviteForm />}></Route>
                  <Route path={"/user-list"} element={<UserList />}></Route>
                  <Route path={"/edit-user"} element={<UserList />}></Route>
                  <Route
                    path={"/license-audit-trail"}
                    element={<LicenseAuditTrailPage />}
                  ></Route>
                  <Route path={"/logs"} element={<AppLogPage />}></Route>
                  <Route path={"/db-backup-restore"} element={<DatabaseBackupRestorePage />}></Route>
                </Routes>
              </div>
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="unauthenticated">
              <CloudFramePrimaryButton
                style={{ marginLeft: "5px" }}
                onClick={handleLogin}
                text={"Login with your account"}
              ></CloudFramePrimaryButton>
            </div>
          </UnauthenticatedTemplate>

          <DefaultPageFooterComponent
            textCloudForm="CloudFrame"
            textCopyRight="@Copyright 2023"
          />
        </BrowserRouter>
      </MsalProvider>
    </div>
  );
}

export default App;
