import useStyles from "./list-card-item.styles";

const ListCardItem = (props: any) => {
  const { title, description, onClick } = props;
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={classes.creditWrapper}
      style={{
        border: "1px solid #eee",
        padding: ".7rem",
        borderRadius: 10,
        cursor: "pointer",
        boxShadow:
          "0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{ backgroundColor: "#292c6d" }}
        className={classes.cardIcon}
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M488.727 66.494H23.273C10.42 66.494 0 76.914 0 89.767v332.466c0 12.853 10.42 23.273 23.273 23.273h465.454c12.853 0 23.273-10.42 23.273-23.273V89.767c0-12.853-10.42-23.273-23.273-23.273z"
          fill="#1f2755"
          data-original="#5286f9"
          style={{ width: "24px" }}
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M273.776 189.773c5.115 5.86 9.57 12.31 13.236 19.242 7.427 14.041 11.655 30.026 11.655 46.986s-4.228 32.943-11.655 46.986c-3.666 6.932-8.121 13.38-13.236 19.24-5.264 6.031-11.23 11.427-17.776 16.069 16.454 11.664 36.523 18.553 58.182 18.553 55.608 0 100.849-45.241 100.849-100.848S369.79 155.152 314.182 155.152c-21.659 0-41.728 6.886-58.182 18.553 6.544 4.642 12.51 10.039 17.776 16.068z"
          fill="#f0425c"
          data-original="#ffb655"
          style={{ width: "24px" }}
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M287.012 209.016c-3.666-6.934-8.121-13.382-13.236-19.242-5.267-6.031-11.231-11.425-17.776-16.066-16.452-11.667-36.523-18.553-58.182-18.553-55.608 0-100.848 45.241-100.848 100.848s45.241 100.848 100.848 100.848c21.659 0 41.73-6.887 58.182-18.553 6.546-4.641 12.51-10.038 17.776-16.067 5.115-5.86 9.57-12.31 13.236-19.24 7.427-14.043 11.655-30.028 11.655-46.986 0-16.964-4.228-32.948-11.655-46.989z"
          fill="#ef8641"
          data-original="#d8143a"
          style={{ width: "24px" }}
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M197.818 356.851c-55.608 0-100.848-45.241-100.848-100.848s45.241-100.848 100.848-100.848c21.659 0 41.728 6.886 58.182 18.553V66.494H23.273C10.42 66.494 0 76.914 0 89.767v332.466c0 12.853 10.42 23.273 23.273 23.273H256v-107.21c-16.454 11.666-36.523 18.555-58.182 18.555z"
          fill="#1f2755"
          data-original="#3d6deb"
          style={{ width: "24px" }}
        />
      </svg>

      <div className={classes.creditName}>
        <div className={classes.creditType}>{title}</div>
        <div className={classes.creditStatus}>{description}</div>
      </div>
    </div>
  );
};

export default ListCardItem;
