import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  creditWrapper: {
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 500,
  },
  cardIcon: {
    width: "38px",
    // paddingBlock: "3px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    marginRight: "1.1rem",
  },
  creditStatus: {
    fontSize: "13px",
    fontWeight: "normal",
    color: "grey",
  },
  creditType: {
    marginBottom: "6px",
  },
  creditName: {
    fontWeight: "500",
    fontSize: "15px",
  }
});

export default useStyles;
