import { makeStyles } from "@fluentui/react-components";

export const forbiddenContainer = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  message: {
    fontSize: '18px'
  }
})
  