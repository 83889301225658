import { gql } from "@apollo/client";

export const QUERY_GET_DB_LIST = gql`
  query GetDbBackups {
    getDatabaseInfos {
      id
      databaseTitle
      databaseName
      databaseHost
      databasePort
      connectionString
      databaseType
      lastArchived
      lastRestored
    }
  }
`;

export const QUERY_GET_BACKUP_LIST = gql`
query GetDbBackupListByDatabaseId($databaseId: Float!) {
  getDbBackupListByDatabaseId(id: $databaseId) {
    id
    backupDate
    backupFile
    lastRestoreDate
    lastRestoredBy
  }
}
`;