import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";

const example = [
  { name: "Active", value: 150 },
  { name: "Expired", value: 55 },
  { name: "Renewed", value: 85 },
  { name: "Expire Next month", value: 30 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChartComponent = (props: any) => {
  const {data} = props;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={100} height={100} >
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          width={200}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={150}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend layout="vertical" align="right" verticalAlign="top" iconType="circle"/>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
