import { useQuery } from "@apollo/client";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  IColumn,
  IIconProps,
  Icon,
  IconButton,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { QUERY_GET_LICENSE_AUDIT_LOG } from "../../graphql-query/license.query";
import {
  formatDateTime,
  toDateTimeString,
  toISOShortDateString,
} from "../../utils/date.utils";
import { parseJson } from "../../utils/string.utils";
import { useStyles } from "./audit-trail.style";

const LicenseAuditTrail = () => {
  const licenseListQuery = useQuery(QUERY_GET_LICENSE_AUDIT_LOG);
  const [licenseList, setLicenseList] = useState<Array<any>>([]);
  const [showLicenseDetails, setShowLicenseDetail] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<any>({});

  const classes = useStyles();
  const contClasses = useContainerStyles();

  const searchIcon: IIconProps = { iconName: "Search" };

  const getFormattedList = (data: any[]): any[] => {
    if (data) {
      return data.map((license) => {
        const licenseInfo = parseJson(license.newData ?? license.oldData);
        const effectiveDate = licenseInfo.effectiveDate
          ? toISOShortDateString(licenseInfo.effectiveDate)
          : "";
        const expiryDate = licenseInfo.expiryDate
          ? toISOShortDateString(licenseInfo.expiryDate)
          : "";
        return {
          ...license,
          effectiveDate: effectiveDate,
          expiryDate: expiryDate,
          licenseInfo: licenseInfo,
          linesOfCode: licenseInfo.linesOfCode ?? "",
        };
      });
    }
    return [];
  };

  useEffect(() => {
    loadLicenseList();
  }, [licenseListQuery.data]);

  const loadLicenseList = async () => {
    let data: Array<any> = licenseListQuery.data?.getLicenseAuditLogs;
    const formattedData = getFormattedList(data);
    setLicenseList([...formattedData]);
  };

  const onViewLicense = (row: any) => {
    setSelectedLicense(row);
    setShowLicenseDetail(true);
  };

  const columns: IColumn[] = [
    {
      key: "eventDate",
      name: "Event date",
      fieldName: "eventDate",
      minWidth: 100,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => <p>{toDateTimeString(props.eventDate)}</p>,
    },
    {
      key: "entity",
      name: "Event matter",
      fieldName: "entity",
      minWidth: 100,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => <p>{props.entity}</p>,
    },
    {
      key: "eventType",
      name: "Event type",
      fieldName: "eventType",
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => <p>{props.eventType}</p>,
    },
    {
      key: "eventCreator",
      name: "Event creator",
      fieldName: "eventCreator",
      minWidth: 180,
      maxWidth: 280,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => <p>{props.eventCreator}</p>,
    },
    {
      key: "eventId",
      name: "License Id / Activation key",
      fieldName: "eventId",
      minWidth: 220,
      maxWidth: 300,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => (
        <p
          className={props.entity === "License" ? contClasses.link : ""}
          onClick={() => {
            if (props.entity === "License") {
              try {
                onViewLicense(props.licenseInfo);
              } catch (e) {
                console.log(e);
              }
            }
          }}
        >
          {props.eventId}
        </p>
      ),
    },
    {
      key: "effectiveDate",
      name: "Effective date",
      fieldName: "effectiveDate",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => <p>{props.effectiveDate}</p>,
    },
    {
      key: "expiryDate",
      name: "Expiry date",
      fieldName: "expiryDate",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => <p>{props.expiryDate}</p>,
    },
    {
      key: "linesOfCode",
      name: "LOC",
      fieldName: "linesOfCode",
      minWidth: 100,
      maxWidth: 160,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => <p>{props.linesOfCode}</p>,
    },
  ];

  const filterList = (event: any) => {
    const licenses = licenseListQuery.data?.getLicenseAuditLogs;
    if (!event.currentTarget.value) {
      setTimeout(() => {
        setLicenseList(licenses);
      });
    }
    let textFilter = event.currentTarget.value;
    if (textFilter) {
      textFilter = textFilter?.toLowerCase();
      const data = licenses.filter(
        (l: any) =>
          `${toDateTimeString(l.eventDate)}`.includes(textFilter) ||
          `${l.entity}`.toLowerCase().includes(textFilter) ||
          `${l.eventType}`.toLowerCase().includes(textFilter) ||
          `${l.eventCreator}`.toLowerCase().includes(textFilter) ||
          `${l.eventId}`.toLowerCase().includes(textFilter) ||
          `${l.effectiveDate}`.includes(textFilter) ||
          `${l.expiryDate}`.includes(textFilter) ||
          `${l.linesOfCode}`.includes(textFilter)
      );
      const formattedData = getFormattedList(data);
      setLicenseList([...formattedData]);
    } else {
      const formattedData = getFormattedList(licenses);
      setLicenseList(getFormattedList([...formattedData]));
    }
  };

  return (
    <div style={{ marginTop: "3rem" }}>
      <h3>License Audit Trail</h3>
      <hr />
      <div className={contClasses.flexStartContainer}>
        <CloudFrameTextBox
          placeholder="Search"
          iconProps={searchIcon}
          style={{ width: "20rem" }}
          onChange={(e) => filterList(e)}
        />
      </div>
      <div style={{ width: "auto", marginTop: "1rem" }}>
        <CloudFrameDataTable
          items={licenseList || []}
          selectionMode={SelectionMode.none}
          columns={columns}
          className={classes.tableBody}
          onActiveItemChanged={(item) => setSelectedLicense(item)}
        />
      </div>
      <Modal
        titleAriaId={"titleId"}
        isOpen={showLicenseDetails}
        onDismiss={() => setShowLicenseDetail(false)}
        isModeless={false}
        isBlocking={true}
        // containerClassName={contentStyles.container}
        dragOptions={undefined}
        styles={{
          main: { borderRadius: 20, overflowY: "hidden", position: "fixed" },
        }}
      >
        <div style={{ width: "35rem", padding: "2rem" }}>
          <div className={contClasses.flexSpaceBtwnContainer}>
            <Icon iconName="TextDocument" style={{ fontSize: "2rem" }} />
            <IconButton
              iconProps={{ iconName: "Cancel", style: { fontSize: "1.3rem" } }}
              ariaLabel="Close popup modal"
              onClick={() => setShowLicenseDetail(false)}
            />
          </div>
          <h3>License details</h3>
          <hr />
          <div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Id</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.licenseId}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Customer Name</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.customerName}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Email</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.email}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Effective Date</strong>
              </p>
              <p style={{ flex: 1 }}>
                {formatDateTime(new Date(selectedLicense.effectiveDate))}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Expiry date</strong>
              </p>
              <p style={{ flex: 1 }}>
                {formatDateTime(new Date(selectedLicense.expiryDate))}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>License Type</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.licenseType}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Product</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.product}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Lines of code</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.linesOfCode}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>License Mode</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isOfflineLicense ? "Embedded" : "Subscription"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Customer is a reseller</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isCustomerReseller ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Product training provided</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.productTrainingProvided ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Evaluation license</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isEvaluationLicense ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Enabled CDN</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.enableCDN ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Lines of code category</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.linesOfCodeCategory === 0
                  ? "Copybook included in programs"
                  : "Copybook Separated from programs"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Support level</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.supportLevel}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "50%" }}>
                <strong>Allowed programs for migration</strong>
              </p>
              <p style={{ width: "50%", wordWrap: "break-word" }}>
                {selectedLicense.allowedProgram}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "50%" }}>
                <strong>Allowed JCLs for migration</strong>
              </p>
              <p style={{ width: "50%", wordWrap: "break-word" }}>
                {selectedLicense.allowedJcl}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Consulting hours</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.consultingHours}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Jira Ticket</strong>
              </p>
              <p style={{ flex: 1 }}>
                <a target="_blank" href={selectedLicense.jiraTicket}>
                  {selectedLicense.jiraTicket}
                </a>
              </p>
            </div>
            {selectedLicense.status === "DECLINED" && (
              <>
                <div style={{ display: "flex" }}>
                  <p style={{ flex: 1 }}>
                    <strong>Decline Reason</strong>
                  </p>
                  <p style={{ flex: 1 }}>{selectedLicense.denialReason}</p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ flex: 1 }}>
                    <strong>Declined By</strong>
                  </p>
                  <p style={{ flex: 1 }}>{selectedLicense.approvedBy}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LicenseAuditTrail;
