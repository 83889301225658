export const getFormattedDate = (date: any) => {
  const dateObj = new Date(date);
  const options: any = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = dateObj.toLocaleDateString("en-US", options);
  return formattedDate;
};

export function formatDateTime(date: Date): string {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const hour = hours % 12 || 12;
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year} ${hour}:${minutes
    .toString()
    .padStart(2, "0")}${amOrPm}`;
}

export const preserveDateTimeFormat = (dateStr: string): string => {
  if (dateStr) {
    const formattedStr = dateStr.replace("T", " ").replace("Z", "");
    return formattedStr;
  }
  return "";
};

export const getStartingOfDay = (date?: Date | null): Date => {
  const day = date || new Date();
  day.setHours(0);
  day.setMinutes(0);
  day.setSeconds(0);
  day.setMilliseconds(0);
  return day;
};

export const getEOD = (date?: Date | null): Date => {
  const day = date || new Date();
  day.setHours(23);
  day.setMinutes(59);
  day.setSeconds(59);
  day.setMilliseconds(999);
  return day;
};

export const getTomorrow = (date?: Date | string | null): Date => {
  const day = new Date(date || new Date());
  day.setDate(day?.getDate() + 1);
  return day;
};

export const toISODateString = (date?: Date | null): string => {
  const day = date || new Date();
  const dayOfMonth = day.getDate().toString().padStart(2, "0");
  const month = (day.getMonth() + 1).toString().padStart(2, "0");
  const year = day.getFullYear();
  const hours = day.getHours();
  const minutes = day.getMinutes();
  const seconds = day.getSeconds();
  const mills = day.getMilliseconds();
  return `${year}-${month}-${dayOfMonth} ${hours}:${minutes}:${seconds}.${mills}`;
};

export const toISOShortDateString = (
  date?: Date | number | string | null
): string => {
  if (typeof date === "string" || typeof date === "number") {
    date = new Date(date);
  }
  const day = date || new Date();
  const dayOfMonth = day.getDate().toString().padStart(2, "0");
  const month = (day.getMonth() + 1).toString().padStart(2, "0");
  const year = day.getFullYear();
  return `${year}-${month}-${dayOfMonth}`;
};

export const toISOShortDateStringStrict = (
  date: Date | string | number | null
): string => {
  if (date == null) return "";
  if (typeof date === "string" || typeof date === "number") {
    date = new Date(date);
  }
  try {
    const dayOfMonth = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${dayOfMonth}`;
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const toDateTimeString = (
  date?: Date | number | string | null
): string => {
  if (typeof date === "string" || typeof date === "number") {
    date = new Date(date);
  }
  const day = date || new Date();
  const dayOfMonth = day.getDate().toString().padStart(2, "0");
  const month = (day.getMonth() + 1).toString().padStart(2, "0");
  const year = day.getFullYear();
  const hours = day.getHours();
  const minutes = day.getMinutes();
  const seconds = day.getSeconds();
  return `${year}-${month}-${dayOfMonth} ${hours}:${minutes}:${seconds}`;
};
