import { makeStyles } from "@fluentui/react-components";

export const useStyles = makeStyles({
  root: {
    paddingLeft: "1rem",
    "@media(max-width: 700px)": {
      paddingLeft: 0,
    },
  },
  mt16: {
    marginTop: "1rem",
  },
  formBody1: {
    maxWidth: "500px",

    backgroundColor: "teal",
  },
  formBody: {
    maxWidth: "900px",
    paddingLeft: "2rem",
    paddingRight: "3rem",
    paddingBottom: "4rem",
    paddingTop: "2rem",
    // marginBlock: "0 auto",
    backgroundColor: "#fff",
    borderLeftRadius: "25px",
    borderRightRadius: "25px",
    borderTopRadius: "25px",
    borderBottomRadius: "25px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "5rem",
  },
  ml2: {
    marginLeft: "2rem",
  },
  mb8: {
    marginBottom: "1rem",
  },
  subContainer: {
    paddingLeft: "2rem",
    paddingRight: "25rem",
    position: "relative",
    "@media(max-width: 1018px)": {
      paddingRight: "12rem",
    },
    "@media(max-width: 700px)": {
      paddingLeft: "1rem",
      paddingRight: "0rem",
    },
  },
  btnContainer: {
    position: "absolute",
    right: "25rem",
    "@media(max-width: 1018px)": {
      right: "12rem",
    },
  },
});
