import { useQuery } from "@apollo/client";
import { CloudFrameStack } from "@cloudframe/stack";
import { useLocation, useNavigate } from "react-router-dom";
import LicenseForm from "../components/license-form/license-form";
import { QUERY_GET_LICENSE } from "../graphql-query/license.query";
import Authorization from "../components/auth/authorization";
import { UserPermission } from "../constants/permissions.constants";

const EditLicensePage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  if (!state.licenseId) {
    // user is trying to access edit page without pressing the edit button in dashboard page
    navigate("/dashboard");
  }

  const licenseData = useQuery(QUERY_GET_LICENSE, {
    variables: { id: state.licenseId },
  });

  if (licenseData.loading) {
    return <p>Loading...</p>;
  }

  if (licenseData.error) {
    return <h3>Something went wrong! Try again</h3>;
  }

  return (
    <Authorization>
      <section key={UserPermission.P_RENEW_LICENSE}>
        <div style={{ margin: 16 }}>
          <CloudFrameStack>
            <LicenseForm data={licenseData.data?.getLicense} />
          </CloudFrameStack>
        </div>
      </section>
    </Authorization>
  );
};

export default EditLicensePage;
