import { Rating, RatingSize } from "@fluentui/react";

const RenovateUpSell = () => {
  return (
    <div className="dashboard-tile" style={{ width: "22.5rem", padding: 0 }}>
      <div style={{ padding: "1rem" }}>
        <div style={{ height: "16rem" }}>
          <div style={{ display: "flex", gap: ".5rem" }}>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "4rem",
                width: "4rem",
                backgroundColor: "rgba(15,87,89,.5)",
                borderRadius: "50%",
                fontSize: "1rem",
                fontWeight: "bold",
                textTransform: "uppercase",
                margin: 0,
                marginRight: ".5rem",
              }}
            >
              {"RN"}
            </p>
            <h3>Renovate</h3>
          </div>
          <p style={{ color: "grey" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco
          </p>
          <ul style={{ color: "grey" }}>
            <li>lorem ipsum dolar amet</li>
            <li>consectetur adipiscing elit</li>
            <li>sed do eiusmod tempor incididunt</li>
          </ul>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            borderTop: "1px solid #eee",
            borderRight: "1px solid #eee",
            width: "100%",
          }}
        >
          <div
            style={{
              padding: ".5rem",
              marginLeft: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Rating
              max={5}
              size={RatingSize.Large}
              defaultRating={4.5}
              rating={4.5}
              ariaLabel="Large stars"
              ariaLabelFormat="{0} of {1} stars"
            />
            <h3 style={{ margin: 0, color: "grey", marginTop: "-4px" }}>
              <a style={{ textDecoration: "none" }} href="#">
                Try now <span style={{ fontSize: "2rem" }}>&rarr;</span>
              </a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenovateUpSell;
