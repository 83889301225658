export const padTwoDigits = (num: number): string => {
  if (num < 10 && num !== 0) {
    return `0${num}`;
  }
  return num.toString();
};

export const padDigits = (num: number, pad: number): string => {
  return num.toLocaleString("en-US", {
    minimumIntegerDigits: pad,
    useGrouping: false,
  });
};

export const parseInt = (value: any): number => {
  let num = Number(value);
  return Number.isNaN(num) ? 0 : num;
}
