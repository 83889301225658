import { initializeIcons } from "@fluentui/react";
import LicenseAuditTrail from "../../components/audit-trail/license-audit-trail";
import Authorization from "../../components/auth/authorization";
import { UserPermission } from "../../constants/permissions.constants";
import { useContainerStyles } from "../../@styles/container.styles";

const LicenseAuditTrailPage = () => {
  const contClasses = useContainerStyles();
  initializeIcons();
  return (
    <Authorization>
      <section
        key={UserPermission.P_LICENSE_AUDIT_TRAIL}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div style={{ marginTop: "3rem" }}>
              <div style={{ paddingLeft: "1rem", width: "90%" }}></div>
              <LicenseAuditTrail />
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default LicenseAuditTrailPage;
