import { useQuery } from "@apollo/client";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { IColumn, IIconProps, SelectionMode } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { useListStyles } from "../../@styles/list.styles";
import { AzureProfile } from "../../@types/auth.types";
import { UserPermission } from "../../constants/permissions.constants";
import { QUERY_GET_USER_LIST } from "../../graphql-query/user.query";
import { toISOShortDateString } from "../../utils/date.utils";
import { getActiveUsers } from "../../utils/ms.graph.utils";
import Authorization from "../auth/authorization";

const UserList = () => {
  const userListQuery = useQuery(QUERY_GET_USER_LIST);
  const [userList, setUserList] = useState<Array<any>>([]);

  const classes = useListStyles();
  const contClasses = useContainerStyles();
  const listClasses = useListStyles();

  const searchIcon: IIconProps = { iconName: "Search" };

  const loadUserStatus = async (formattedData: any[]): Promise<void> => {
    const activeProfiles: AzureProfile[] = await getActiveUsers();
    const profileMap = {};
    activeProfiles.forEach((profile) => {
      profileMap[profile.userPrincipalName] = true;
    });
    formattedData.forEach((user) => {
      if (profileMap[user.email]) {
        user.userStatus = "Active";
      } else {
        user.userStatus = "Inactive";
      }
    });
  };

  useEffect(() => {
    loadUserList().then(() => {
      console.log("");
    });
  }, [userListQuery.data]);

  const loadUserProps = (users: any[]): any[] => {
    return users.map((user) => {
      return {
        ...user,
        roles: user.roles,
        loginStatus: "Active",
        userStatus: "Loading...",
        activationDate: user.activationDate
          ? toISOShortDateString(Number(user.activationDate))
          : "",
        invitationDate: user.invitationDate
          ? toISOShortDateString(Number(user.invitationDate))
          : "",
      };
    });
  };

  const loadUserList = async () => {
    let data: Array<any> = userListQuery.data?.getUserList;
    if (data) {
      const formattedData = loadUserProps(data);
      loadUserStatus(formattedData).then(() => {
        setUserList([...formattedData]);
      });
    }
  };

  const columns: IColumn[] = [
    {
      key: "ulist_sl",
      name: "SL",
      fieldName: "sl",
      minWidth: 24,
      maxWidth: 32,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
      onRender: (user, index) => <p>{index ? index + 1 : 1}</p>,
    },
    {
      key: "ulist_name",
      name: "User Name",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "ulist_email",
      name: "Email",
      fieldName: "email",
      minWidth: 100,
      maxWidth: 300,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "ulist_roles",
      name: "Role(s)",
      fieldName: "roles",
      minWidth: 100,
      maxWidth: 240,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    // {
    //   key: "ulist_loginStatus",
    //   name: "Login Status",
    //   fieldName: "loginStatus",
    //   minWidth: 90,
    //   maxWidth: 90,
    //   isRowHeader: true,
    //   isResizable: true,
    //   // isSorted: false,
    //   // isSortedDescending: false,
    //   // sortAscendingAriaLabel: "Sorted A to Z",
    //   // sortDescendingAriaLabel: "Sorted Z to A",
    //   isFiltered: true,
    //   data: "string",
    //   isPadded: true,
    // },
    {
      key: "ulist_invitationDate",
      name: "Invitation Date",
      fieldName: "invitationDate",
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "ulist_activationDate",
      name: "Activation Date",
      fieldName: "activationDate",
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "ulist_userStatus",
      name: "User Status",
      fieldName: "userStatus",
      minWidth: 90,
      maxWidth: 90,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
  ];

  const filterList = (event: any) => {
    const users = loadUserProps([...userListQuery.data?.getUserList]);
    let textFilter = event.currentTarget.value;
    if (!textFilter) {
      setTimeout(() => {
        setUserList(users);
      });
    } else {
      const data = users.filter(
        (l: any) =>
          `${l.name}`.toLowerCase().includes(textFilter) ||
          `${l.email}`.toLowerCase().includes(textFilter) ||
          `${l.roles}`.toLowerCase().includes(textFilter) ||
          `${l.activationDate}`.includes(textFilter) ||
          `${l.invitationDate}`.includes(textFilter) ||
          `${l.loginStatus}`.toLowerCase().includes(textFilter) ||
          `${l.userStatus}`.toLowerCase().includes(textFilter)
      );
      setUserList(data);
    }
  };

  return (
    <Authorization>
      <section
        key={UserPermission.P_INVITE_USER}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div style={{ marginTop: "3rem" }}>
              <h3>User List</h3>
              <hr />
              <div className={contClasses.flexStartContainer}>
                <CloudFrameTextBox
                  placeholder="Search"
                  iconProps={searchIcon}
                  onChange={(e) => filterList(e)}
                  className={listClasses.searchBar}
                />
              </div>
              <div style={{ width: "auto", marginTop: "1rem" }}>
                <CloudFrameDataTable
                  items={userList || []}
                  selectionMode={SelectionMode.none}
                  columns={columns}
                  className={`${classes.tableBody}`}
                  compact
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default UserList;
