import { CloudFrameIconButton } from "@cloudframe/button";
import { IActionButtons } from "./action-button.intefaces";
import { initializeIcons, IIconProps } from "@fluentui/react";
import Authorization from "../auth/authorization";

const ActionButtons = (props: IActionButtons) => {
  const { onEdit, onDelete, onView, onDownload, onDownloadDcio, row, buttonTitles } = props;

  initializeIcons();

  const editIcon: IIconProps = {
    iconName: "PageEdit",
    style: {
      color: "green",
      transform: "scale(1.5)",
      marginBlock: "5px",
      marginRight: "1rem",
    },
  };
  const removeIcon: IIconProps = {
    iconName: "RemoveFromShoppingList",
    style: {
      color: row.status === "REVOKED" ? "grey" : "red",
      transform: "scale(1.5)",
      marginBlock: "5px",
    },
  };

  const downloadIcon: IIconProps = {
    iconName: "DownloadDocument",
    style: {
      color: "blue",
      transform: "scale(1.4)",
      marginBlock: "5px",
    },
  };

  const downloadDcioIcon: IIconProps = {
    iconName: "AddIn",
    style: {
      color: "blue",
      transform: "scale(1.4)",
      marginBlock: "5px",
    },
  };

  const viewIcon: IIconProps = {
    iconName: "TextDocument",
    style: {
      color: "rgba(23,12,12,1)",
      transform: "scale(1.5)",
      marginBlock: "5px",
      marginBottom: "5px",
      marginRight: "1.5rem",
    },
  };

  return (
    <>
      <Authorization>
        {buttonTitles.viewTitle && (
          <CloudFrameIconButton
            iconProps={viewIcon}
            title={buttonTitles.viewTitle}
            key={`P_${buttonTitles.viewTitle
              ?.replaceAll(" ", "_")
              .toUpperCase()}`}
            onClick={() => onView ? onView(row) : console.log("Not found")}
          />
        )}
        {buttonTitles.editTitle && (
          <CloudFrameIconButton
            iconProps={editIcon}
            title={buttonTitles.editTitle}
            key={`P_${buttonTitles.editTitle
              ?.replaceAll(" ", "_")
              .toUpperCase()}`}
            onClick={onEdit}
          />
        )}
        {buttonTitles.deleteTitle && (
          <CloudFrameIconButton
            iconProps={removeIcon}
            title={buttonTitles.deleteTitle}
            key={`P_${buttonTitles.deleteTitle
              ?.replaceAll(" ", "_")
              .toUpperCase()}`}
            disabled={row?.status === "REVOKED"}
            onClick={onDelete}
          />
        )}
        {buttonTitles.downloadTitle && (
          <CloudFrameIconButton
            iconProps={downloadIcon}
            title={buttonTitles.downloadTitle}
            key={`P_${buttonTitles.downloadTitle
              ?.replaceAll(" ", "_")
              .toUpperCase()}`}
            onClick={onDownload}
          />
        )}
        {buttonTitles.downloadDcioTitle && (
          <CloudFrameIconButton
            iconProps={downloadDcioIcon}
            title={buttonTitles.downloadDcioTitle}
            key={`P_${buttonTitles.downloadDcioTitle
              ?.replaceAll(" ", "_")
              .toUpperCase()}`}
            onClick={onDownloadDcio}
          />
        )}
      </Authorization>
    </>
  );
};

export default ActionButtons;
