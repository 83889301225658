import { CloudFrameDefaultButton } from "@cloudframe/button";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/back-button/back-button";
import { IWelcomeTile } from "./welcome-tile.interfaces";

const WelcomeTile = (props: IWelcomeTile) => {
  const { customerName, onClickShowModal } = props;

  const navigate = useNavigate();

  return (
    <div
      style={{
        marginTop: "0rem",
        backgroundColor: "#fff",
        padding: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        marginBottom: "1rem",
      }}
      className="dashboard-tile"
    >
      <div>
        {customerName && (
          <div
            style={{
              marginTop: "-1rem",
              marginLeft: "-.5rem",
              marginBottom: "2rem",
            }}
          >
            <BackButton onClick={() => navigate("/dashboard")} />
          </div>
        )}
        <h3>{customerName || "Welcome to Dashboard"}</h3>
        <p style={{ color: "grey", marginTop: "-.5rem" }}>
          {customerName
            ? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            : "Good afternoon, You can check your license usage statistics and also send a request to renew your license from here"}
        </p>
        {customerName && (
          <div style={{ marginTop: "2rem" }}>
            <CloudFrameDefaultButton
              text="Contact details"
              onClick={onClickShowModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeTile;
