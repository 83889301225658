import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DefaultOptions,
  InMemoryCache,
  Observable,
  createHttpLink,
} from "@apollo/client";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { acquireTokenSilently, msalConfig } from "./auth-config";
import "./index.css";
import userStore from "./redux/auth/userStore";
import reportWebVitals from "./reportWebVitals";
import { setContext } from "@apollo/client/link/context";
import { toISODateString } from "./utils/date.utils";

export const msalInstance = new PublicClientApplication(msalConfig);

export const softLogoutUser = () => {
  if (window.confirm("Your session timed out! Please login again.")) {
    sessionStorage.clear();
    window.location.href = window.location.origin;
  }
};

function AppRoot() {
  const [token, setToken] = useState<AuthenticationResult | null>(null);
  const [isTokenLoading, setTokenLoading] = useState(false);
  const baseURL = process.env.REACT_APP_API_URL;

  /**
   * Query configuration for ApploClient object. Used to control GraphQL
   * query behavior.
   */
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  /**
   * Makes token refresh call to Microsoft AD. When token received,
   * stores the token to app state.
   */
  const refreshToken = () => {
    if (msalInstance.getActiveAccount() && !isTokenLoading) {
      setTokenLoading(true);
      acquireTokenSilently(msalInstance).then(
        (acquiredToken: AuthenticationResult | null) => {
          setTokenLoading(false);
          if (acquiredToken) {
            setToken(acquiredToken);
            sessionStorage.setItem("cfa", acquiredToken.accessToken);
          } else {
            softLogoutUser();
          }
        }
      );
    }
  };

  /**
   * ID token from Microsoft Azure AD expires after 1 hour of generation.
   * This methods determines safe time (10 minutes before expiration) to
   * regenerate the token.
   * @returns true if the safe expiration time is passed
   */
  const isTokenExpired = (): boolean => {
    let safeExpiryTime = token?.expiresOn?.getTime();
    if (safeExpiryTime) {
      safeExpiryTime = safeExpiryTime - 10 * 60 * 1000; // 10 minutes prior to actual expiration
      const now = new Date().getTime();
      return safeExpiryTime < now;
    } else {
      return true;
    }
  };

  const responseInterceptor = new ApolloLink((operation, forward) => {
    // let observable = forward(operation);
    // observable.subscribe(
    //   (response) => response,
    //   (error) => {console.log("error from interceptor", error)}
    // );
    return forward(operation).map((response) => {
      return response;
    });
  });

  const httpLink = createHttpLink({
    uri: baseURL,
  });

  /**
   * Mechanism to handle ID Token in the 'Authorization' header. Also watches for
   * expired token and handles token refresh call. For more details, please visit -
   * https://www.apollographql.com/docs/react/networking/advanced-http-networking
   */
  const authLink = setContext((_, { headers }) => {
    const isTokenInvalid = !token || isTokenExpired();
    if (isTokenInvalid) {
      refreshToken();
    }
    return {
      headers: {
        ...headers,
        authorization: token?.idToken ?? sessionStorage.getItem("cfa") ?? "",
        "Content-Security-Policy": "upgrade-insecure-requests"
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([responseInterceptor, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    defaultOptions,
  });

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  /**
   * Handles login and after successful login preserve the received access token
   * in app state. Also refreshes the access token after every 50 minutes. The
   * timeout duration for the access token is 60 minutes.
   */
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      setToken(payload);
      sessionStorage.setItem("cfa", payload.accessToken);
      setInterval(() => {
        refreshToken();
      }, 50 * 60 * 1000);
    }
  });

  // eslint-disable-next-line no-extend-native
  Date.prototype.toISOString = function () {
    return toISODateString(this);
  };

  return (
    <ApolloProvider client={client}>
      <React.StrictMode>
        <App pca={msalInstance} />
      </React.StrictMode>
    </ApolloProvider>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={userStore}>
    <AppRoot />
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
