export enum UserPermission {
  P_HOME = "P_HOME",
  P_CREATE_ACTIVATION_KEY = "P_CREATE_ACTIVATION_KEY",
  P_CREATE_NEW_LICENSE = "P_CREATE_NEW_LICENSE",
  P_RENEW_LICENSE = "P_RENEW_LICENSE",
  P_CONTACT_US = "P_CONTACT_US",
  P_APPROVE_LICENSE = "P_APPROVE_LICENSE",
  P_DECLINE_LICENSE = "P_DECLINE_LICENSE",
  P_REVOKE_LICENSE = "P_REVOKE_LICENSE",
  P_DOWNLOAD_LICENSE = "P_DOWNLOAD_LICENSE",
  P_VIEW_LICENSE = "P_VIEW_LICENSE",
  P_PERMISSION_DETAILS = "P_PERMISSION_DETAILS",
  P_EDIT_PERMISSION = "P_EDIT_PERMISSION",
  P_ROLE_DETAILS = "P_ROLE_DETAILS",
  P_EDIT_ROLE = "P_EDIT_ROLE",
  P_INVITE_USER = "P_INVITE_USER",
  P_INVITATION_LIST = "P_INVITATION_LIST",
  P_USER_LIST = "P_USER_LIST",
  P_LICENSE_LIST = "P_LICENSE_LIST",
  P_DASHBOARD = "P_DASHBOARD",
  P_DASHBOARD_CLIENT = "P_DASHBOARD_CLIENT",
  P_USER_PERMISSION_FORM = "P_USER_PERMISSION_FORM",
  P_USER_PERMISSION_LIST = "P_USER_PERMISSION_LIST",
  P_USER_ROLE_FORM = "P_USER_ROLE_FORM",
  P_USER_ROLE_LIST = "P_USER_ROLE_LIST",
  P_EDIT_USER = "P_EDIT_USER",
  P_LICENSE_AUDIT_TRAIL = "P_LICENSE_AUDIT_TRAIL",
  P_APP_LOGS = "P_APP_LOGS",
  P_DATABASE_BACKUP = "P_DATABASE_BACKUP",
  P_DATABASE_RESTORE = "P_DATABASE_RESTORE",
}