import { useQuery } from "@apollo/client";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  IColumn,
  IIconProps,
  Icon,
  IconButton,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { QUERY_GET_LOGS } from "../../graphql-query/license.query";
import {
  formatDateTime,
  toDateTimeString,
  toISOShortDateString,
} from "../../utils/date.utils";
import { parseJson } from "../../utils/string.utils";
import { useStyles } from "./app-log.style";
import {
  CloudFrameDefaultButton,
  CloudFramePrimaryButton,
} from "@cloudframe/button";

const AppLog = () => {
  const appLogQuery = useQuery(QUERY_GET_LOGS);
  const [logs, setLogs] = useState("");

  const classes = useStyles();
  const contClasses = useContainerStyles();

  useEffect(() => {
    loadAppLogs();
  }, [appLogQuery.data]);

  const loadAppLogs = async () => {
    await appLogQuery.refetch();
    let data: any = appLogQuery.data?.getLogContent;
    setLogs(data?.message ?? "");
  };

  return (
    <div style={{ marginTop: "3rem" }}>
      <div
        className={contClasses.flexSpaceBtwnContainer}
        style={{ marginBottom: 0 }}
      >
        <h3>Application Debug Log</h3>
        <CloudFramePrimaryButton
          style={{ padding: "1rem" }}
          title="Refresh Logs"
          iconProps={{ iconName: "refresh" }}
          onClick={() => {
            loadAppLogs();
          }}
        />
      </div>
      <hr />
      <div className={contClasses.flexStartContainer}>
        <textarea
          rows={24}
          style={{
            width: "100%",
            fontFamily: "Courier New, Courier, monospace",
            fontSize: "1rem",
            resize: "none",
            overflowY: "auto",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
          value={logs}
        ></textarea>
      </div>
    </div>
  );
};

export default AppLog;
