import { useMutation } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameCheckbox } from "@cloudframe/checkbox";
import { CloudFrameDatepicker } from "@cloudframe/datepicker";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DayOfWeek,
  IDropdownProps,
  ITextFieldProps,
  defaultDatePickerStrings,
} from "@fluentui/react";
import { FormEvent, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../@styles/css/form-element.css";
import { IForm } from "../../@types/form.types";
import {
  LICENSE_STATUS,
  LICENSE_TYPE,
  PRODUCT,
} from "../../constants/license.constants";
import {
  MUTATION_CREATE_LICENSE,
  MUTATION_EDIT_LICENSE,
} from "../../graphql-mutations/license.mutation";
import { getStartingOfDay, getTomorrow } from "../../utils/date.utils";
import { parseInt } from "../../utils/numeric.utils";
import { isBlank } from "../../utils/string.utils";
import CustomLabel from "../custom-label/custom-label";
import { useStyles } from "./license-form.styles";

const LicenseForm = (props: IForm) => {
  const { data } = props;
  const classes = useStyles();
  const [formValues, setFormValues] = useState<any>({
    isOfflineLicense: false,
  });
  const [errors, setErrors] = useState<any>({});
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createLicense] = useMutation(MUTATION_CREATE_LICENSE);
  const [editLicense] = useMutation(MUTATION_EDIT_LICENSE);
  const [formSubmitAttempted, setFormSubmitAttempted] = useState(false);
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const jiraTicketPattern = /^https:\/\/cloudframe\.atlassian\.net\/.+$/;

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      checkLicenseExpiration(data);
    }
    if (data?.id) {
      setFormValues({
        ...data,
        linesOfCodeCategory:
          data.linesOfCodeCategory === 10000 ? "HIGH" : "LOW",
      });
    } else {
      setFormValues({
        licenseType: "CUSTOMER",
        isCustomerReseller: false,
        product: "Renovate",
        linesOfCode: 0,
        effectiveDate: new Date(),
        expiryDate: getTomorrow(new Date()),
        consultingHours: 1,
        enableCDN: "true",
        isOfflineLicense: true,
        linesOfCodeCategory: "LOW",
        supportLevel: "PREMIUM",
        productTrainingProvided: true,
        isEvaluationLicense: false,
        includeDcio: true,
      });
    }
  }, [data]);

  const validateForm = () => {
    const newErrors: any = {};
    if (isBlank(formValues.licenseType)) {
      newErrors.licenseType = "Please select an option";
    }
    if (isBlank(formValues.isCustomerReseller)) {
      newErrors.isCustomerReseller = "Please select an option";
    }
    if (isBlank(formValues.product)) {
      newErrors.product = "Please select an option";
    }
    if (isBlank(formValues.linesOfCodeCategory)) {
      newErrors.linesOfCodeCategory = "Please select an option";
    }
    if (isBlank(formValues.effectiveDate)) {
      newErrors.effectiveDate = "Please select a date";
    } else {
      const sod = getStartingOfDay(formValues.effectiveDate);
      if (sod < getStartingOfDay(new Date())) {
        newErrors.effectiveDate = "Effective date must be today or onward";
      }
    }
    if (isBlank(formValues.expiryDate)) {
      newErrors.expiryDate = "Please select a date";
    } else {
      const sod = getStartingOfDay(formValues.expiryDate);
      if (sod <= formValues.effectiveDate) {
        newErrors.expiryDate =
          "Expiry date must be greater than effective date";
      }
    }
    if (isBlank(formValues.supportLevel)) {
      newErrors.supportLevel = "Please select an option";
    }
    if (isBlank(formValues.productTrainingProvided)) {
      newErrors.productTrainingProvided = "Please select an option";
    }
    if (isBlank(formValues.isEvaluationLicense)) {
      newErrors.isEvaluationLicense = "Please select an option";
    }
    console.log(formValues.consultingHours);
    if (isBlank(formValues.consultingHours)) {
      newErrors.consultingHours = "Please provide a valid number";
    }
    if (!formValues.email || !emailPattern.test(formValues.email)) {
      newErrors.email = "Pleae enter valid email address";
    }
    if (
      !formValues.jiraTicket ||
      !jiraTicketPattern.test(formValues.jiraTicket)
    ) {
      newErrors.jiraTicket = "Pleae enter valid Jira ticket URL";
    }

    if (formValues.licenseType == LICENSE_TYPE.Training) {
      if (isBlank(formValues.allowedProgram)) {
        newErrors.allowedProgram = "Please provide allowed program hash names";
      }
      if (isBlank(formValues.allowedJcl)) {
        newErrors.allowedJcl = "Please provide allowed JCL hash names";
      }
    }

    setErrors({ ...newErrors });
    return !Object.keys(newErrors).length;
  };

  const updateLicenseDetails = async () => {
    const {
      product,
      customerName,
      email,
      __typename,
      isOfflineLicense,
      ...licenseDataToUpdate
    } = formValues;

    const result = await editLicense({
      variables: {
        data: licenseDataToUpdate,
      },
    });
    if (result.data.editLicense) {
      navigate("/all-licenses");
    }
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitting(true);
    setFormSubmitAttempted(true);
    // const isFormValid = validateForm();
    const isFormValid = true;
    try {
      if (isFormValid && data) {
        await updateLicenseDetails();
      } else if (isFormValid) {
        const loc =
          formValues.product === "Relocate"
            ? Number.MAX_SAFE_INTEGER
            : formValues.linesOfCode;
        const formData = {
          ...formValues,
          enableCDN:
            formValues.enableCDN === "true" &&
            formValues.licenseType !== LICENSE_TYPE.Training &&
            formValues.product !== "Relocate",
          linesOfCode: loc,
          effectiveDate: formValues.effectiveDate?.toISOString(),
          expiryDate: formValues.expiryDate?.toISOString(),
        };
        const result = await createLicense({ variables: { data: formData } });
        if (result.data.createLicense) {
          navigate("/all-licenses");
        }
      }
    } catch (error) {
      console.log(error);
    }
    setFormSubmitting(false);
  };

  const onRenderLabel = (
    props: ITextFieldProps | IDropdownProps | undefined
  ): ReactElement =>
    props ? (
      <CustomLabel id={props.label?.replaceAll(" ", "")} {...props} />
    ) : (
      <div />
    );

  const onLocCategoryNav = () => {
    // eslint-disable-next-line no-restricted-globals
    open(
      "https://internal.cloudframe.com/wp/2021/10/26/generating-licenses-for-customers/",
      "_blank",
      ""
    );
  };

  const getLocCategoryHint = (): ReactElement => {
    return (
      <span>
        Categories are five-digit numbers with each digit correlating to
        different usages. Currently, CloudFrame had allocated only the first
        digit and intends to use the remaining four digits in the future for
        different usage. The below section of the article explains the usage of
        the first digit of the category which is correlating to copybooks. For
        more details, please follow
        <span
          role="link"
          onKeyDown={onLocCategoryNav}
          onClick={onLocCategoryNav}
          style={{ color: "blue", cursor: "pointer" }}
          tabIndex={0}
        >
          {" these instructions."}
        </span>
      </span>
    );
  };

  const isLicenseExpired = (licenseInfo: any): boolean => {
    const effectiveDate = new Date(licenseInfo.effectiveDate);
    const expiryDate = new Date(licenseInfo.expiryDate);
    const currentDate = new Date();
    return effectiveDate <= currentDate && expiryDate < currentDate;
  };

  const checkLicenseExpiration = (licenseInfo: any): void => {
    if (isLicenseExpired(licenseInfo)) {
      licenseInfo.status = LICENSE_STATUS.EXPIRED;
    }
  };

  const onChangeLicenseType = (e: any, item: any) => {
    if ([LICENSE_TYPE.Training].includes(item.key)) {
      setFormValues({
        ...formValues,
        licenseType: item.key,
        linesOfCode: Number.MAX_SAFE_INTEGER,
        enableCDN: "false",
        consultingHours: 0,
      });
    } else if (LICENSE_TYPE.SI === item.key) {
      setFormValues({
        ...formValues,
        licenseType: item.key,
        product: PRODUCT.Renovate,
        includeDcio: true,
      });
    } else {
      setFormValues({
        ...formValues,
        licenseType: item.key,
        includeDcio: false,
      });
    }
    setErrors({ ...errors, licenseType: undefined });
  };

  return (
    <div className={classes.root}>
      <div
        id="form"
        className={classes.formBody}
        style={{ borderRadius: "25px" }}
      >
        <div className={classes.subContainer}>
          <h3>{data ? "Renew License" : "Create new license"}</h3>
          <form onSubmit={onFormSubmit}>
            <CloudFrameDropdown
              placeholder="Select an option"
              label="License Type"
              className={classes.mb8}
              errorMessage={errors.licenseType}
              required
              defaultSelectedKey={[formValues.licenseType]}
              onChange={onChangeLicenseType}
              options={[
                { key: LICENSE_TYPE.Customer, text: LICENSE_TYPE.Customer },
                { key: LICENSE_TYPE.POC, text: LICENSE_TYPE.POC },
                { key: LICENSE_TYPE.Internal, text: LICENSE_TYPE.Internal },
                { key: LICENSE_TYPE.Training, text: LICENSE_TYPE.Training },
                { key: LICENSE_TYPE.Trial, text: LICENSE_TYPE.Trial },
                { key: LICENSE_TYPE.SI, text: LICENSE_TYPE.SI },
                { key: LICENSE_TYPE.End_User, text: "End User" },
                { key: LICENSE_TYPE.ONPREM, text: "On Premise" },
                { key: LICENSE_TYPE.SAAS, text: LICENSE_TYPE.SAAS },
              ]}
            />
            {formValues.licenseType === LICENSE_TYPE.SI && (
              <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                <CloudFrameCheckbox
                  boxSide="start"
                  label="Include DCIO License"
                  styles={{
                    label: {
                      fontWeight: 600,
                    },
                  }}
                  name="includeDcio"
                  checked={formValues.includeDcio}
                  onChange={(_: any, checked: any) => {
                    setFormValues({
                      ...formValues,
                      includeDcio: !!checked,
                    });
                  }}
                  disabled={!!data}
                />
              </div>
            )}
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, customerName: value });
              }}
              required
              label="Legal entity/ Customer name"
              className={classes.mb8}
              value={formValues.customerName}
              disabled={!!data}
            />
            <CloudFrameTextBox
              label="Email"
              className={classes.mb8}
              value={formValues.email}
              required
              disabled={!!data}
              errorMessage={errors.email}
              onChange={(_, value) => {
                setFormValues({ ...formValues, email: value });
                if (!value || !emailPattern.test(value)) {
                  setErrors({
                    ...errors,
                    email: "Please enter valid email address",
                  });
                } else {
                  setErrors({ ...errors, email: undefined });
                }
              }}
            />
            {[LICENSE_TYPE.Training].includes(
              formValues.licenseType
            ) && (
              <CloudFrameTextBox
                label="Allowed programs (Program hashes)"
                ariaLabel="Please enter comma separated program hashes here. Program hashes are calculated from program file content. The license will only allow the given programs to migrate."
                className={classes.mb8}
                value={formValues.allowedProgram}
                errorMessage={errors.allowedProgram}
                onRenderLabel={onRenderLabel}
                onChange={(_, value) => {
                  setFormValues({ ...formValues, allowedProgram: value });
                  if (!value) {
                    setErrors({
                      ...errors,
                      allowedProgram: "Please enter program hashes",
                    });
                  } else {
                    setErrors({ ...errors, allowedProgram: undefined });
                  }
                }}
                required
              />
            )}
            {formValues.licenseType == LICENSE_TYPE.Training && (
              <CloudFrameTextBox
                label="Allowed JCLS (JCL hashes)"
                ariaLabel="Please enter comma separated JCL hashes here. Program hashes are calculated from JCL file content. The license will only allow the given JCLs to migrate."
                className={classes.mb8}
                value={formValues.allowedJcl}
                errorMessage={errors.allowedJcl}
                onRenderLabel={onRenderLabel}
                onChange={(_, value) => {
                  setFormValues({ ...formValues, allowedJcl: value });
                  if (!value) {
                    setErrors({
                      ...errors,
                      allowedJcl: "Please enter JCL hashes",
                    });
                  } else {
                    setErrors({ ...errors, allowedJcl: undefined });
                  }
                }}
              />
            )}
            <CloudFrameDropdown
              placeholder="Select an option"
              label="Customer is a reseller"
              required
              defaultSelectedKey={[
                formValues.isCustomerReseller === undefined
                  ? ""
                  : formValues.isCustomerReseller
                  ? "yes"
                  : "no",
              ]}
              errorMessage={errors.isCustomerReseller}
              onChange={(e, item: any) => {
                setFormValues({
                  ...formValues,
                  isCustomerReseller: item.key === "yes",
                });
                setErrors({ ...errors, isCustomerReseller: undefined });
              }}
              className={classes.mb8}
              options={[
                { key: "yes", text: "Yes" },
                { key: "no", text: "No" },
              ]}
            />

            <CloudFrameDropdown
              placeholder="Select an option"
              label="Product"
              required
              errorMessage={errors.product}
              defaultSelectedKey={[formValues.product]}
              onChange={(e, item: any) => {
                const isRelocate = formValues.product === "Relocate";
                if (isRelocate) {
                  setFormValues({
                    ...formValues,
                    product: item.key,
                    linesOfCode: Number.MAX_SAFE_INTEGER,
                    enableCDN: "false",
                  });
                  setErrors({ ...errors, product: undefined });
                } else {
                  setFormValues({ ...formValues, product: item.key });
                  setErrors({ ...errors, product: undefined });
                }
              }}
              disabled={!!data || formValues.licenseType === LICENSE_TYPE.SI}
              className={classes.mb8}
              options={[
                { key: PRODUCT.Relocate, text: PRODUCT.Relocate },
                { key: PRODUCT.Renovate, text: PRODUCT.Renovate },
                {
                  key: PRODUCT.DCIO_PLUS_SUBSCRIPTION,
                  text: "DCIO Plus Subscription",
                },
                {
                  key: PRODUCT.Code_Navigator,
                  text: "Code Navigator",
                },
              ]}
            />
            {formValues.product !== PRODUCT.DCIO_PLUS_SUBSCRIPTION && (
              <CloudFrameTextBox
                onChange={(_, value = "") => {
                  setFormValues({
                    ...formValues,
                    linesOfCode: parseInt(value),
                  });
                }}
                label="Lines of code"
                type="number"
                required
                value={`${
                  formValues.product === "Relocate"
                    ? Number.MAX_SAFE_INTEGER
                    : formValues.linesOfCode || ""
                }`}
                className={classes.mb8}
                disabled={
                  formValues.product === "Relocate" ||
                  formValues.licenseType == LICENSE_TYPE.Training
                }
              />
            )}
            <CloudFrameDropdown
              placeholder="Select an option"
              label="Lines of code category"
              children={getLocCategoryHint()}
              required
              defaultSelectedKey={[formValues.linesOfCodeCategory]}
              errorMessage={errors.linesOfCodeCategory}
              className={classes.mb8}
              onRenderLabel={onRenderLabel}
              onChange={(e, item: any) => {
                setFormValues({ ...formValues, linesOfCodeCategory: item.key });
                setErrors({ ...errors, linesOfCodeCategory: undefined });
              }}
              options={[
                { key: "LOW", text: "Copybook included in programs" },
                { key: "HIGH", text: "Copybook Separated from programs" },
              ]}
            />
            {formValues.licenseType !== LICENSE_TYPE.Training && (
              <>
                <CloudFrameDatepicker
                  className={classes.mb8}
                  firstDayOfWeek={DayOfWeek.Monday}
                  value={
                    formValues.effectiveDate
                      ? new Date(formValues.effectiveDate)
                      : undefined
                  }
                  label={"Effective Date"}
                  // minDate={new Date()}
                  isRequired={!formValues.effectiveDate}
                  disabled={formValues.licenseType == LICENSE_TYPE.Training}
                  onSelectDate={(date) => {
                    const newEffectiveDate = getStartingOfDay(date);
                    const currentExpiryDate = formValues.expiryDate;
                    const adjustedExpiryDate =
                      currentExpiryDate &&
                      new Date(currentExpiryDate) <
                        new Date(newEffectiveDate || "")
                        ? getTomorrow(newEffectiveDate)
                        : new Date(currentExpiryDate);

                    setFormValues({
                      ...formValues,
                      effectiveDate: newEffectiveDate,
                      expiryDate:
                        adjustedExpiryDate?.toISOString() || undefined,
                    });
                    setErrors({ ...errors, effectiveDate: undefined });
                  }}
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  strings={defaultDatePickerStrings}
                />
                <CloudFrameDatepicker
                  firstDayOfWeek={DayOfWeek.Monday}
                  label={"Expiry Date"}
                  isRequired={!formValues.expiryDate}
                  disabled={formValues.licenseType == LICENSE_TYPE.Training}
                  minDate={getTomorrow(formValues.effectiveDate)}
                  value={
                    formValues.expiryDate
                      ? new Date(formValues.expiryDate)
                      : getTomorrow(formValues.effectiveDate)
                  }
                  onSelectDate={(date) => {
                    setFormValues({
                      ...formValues,
                      expiryDate: date,
                    });
                    setErrors({ ...errors, expiryDate: undefined });
                  }}
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  strings={defaultDatePickerStrings}
                />
              </>
            )}
            <CloudFrameDropdown
              placeholder="Select an option"
              className={classes.mb8}
              required
              defaultSelectedKey={[formValues.supportLevel]}
              errorMessage={errors.supportLevel}
              onChange={(e, item: any) => {
                setFormValues({ ...formValues, supportLevel: item.key });
                setErrors({ ...errors, supportLevel: undefined });
              }}
              label="Support Level"
              options={[
                { key: "PREMIUM", text: "Premium" },
                { key: "PREMIUM_PLUS", text: "Premium plus" },
              ]}
            />
            <CloudFrameDropdown
              placeholder="Select an option"
              className={classes.mb8}
              errorMessage={errors.productTrainingProvided}
              required
              defaultSelectedKey={[
                formValues.productTrainingProvided === undefined
                  ? ""
                  : formValues.productTrainingProvided
                  ? "yes"
                  : "no",
              ]}
              onChange={(e, item: any) => {
                setFormValues({
                  ...formValues,
                  productTrainingProvided: item.key === "yes",
                });
                setErrors({ ...errors, productTrainingProvided: undefined });
              }}
              label="Product training provided?"
              options={[
                { key: "yes", text: "Yes" },
                { key: "no", text: "No" },
              ]}
            />
            <CloudFrameDropdown
              className={classes.mb8}
              placeholder="Select an option"
              label="Is this an evaluation license?"
              required
              defaultSelectedKey={[
                formValues.isEvaluationLicense === undefined
                  ? "no"
                  : formValues.isEvaluationLicense
                  ? "yes"
                  : "no",
              ]}
              errorMessage={errors.isEvaluationLicense}
              onChange={(e, item: any) => {
                setFormValues({
                  ...formValues,
                  isEvaluationLicense: item.key === "yes",
                });
                setErrors({ ...errors, isEvaluationLicense: undefined });
              }}
              options={[
                { key: "yes", text: "Yes" },
                { key: "no", text: "No" },
              ]}
            />
            <CloudFrameTextBox
              label="Consulting hours in agreement"
              className={classes.mb8}
              required
              errorMessage={errors.consultingHours}
              value={`${formValues.consultingHours || "0"}`}
              type="number"
              onChange={(_, value = "") => {
                setFormValues({
                  ...formValues,
                  consultingHours: parseInt(value),
                });
              }}
            />
            <CloudFrameDropdown
              placeholder="Select an option"
              label="Enable CDN for license validation"
              required
              errorMessage={errors.enableCDN}
              defaultSelectedKey={
                formValues.product === "Relocate" ||
                formValues.licenseType === LICENSE_TYPE.Training
                  ? ["false"]
                  : [formValues.enableCDN]
              }
              onChange={(e, item: any) => {
                setFormValues({ ...formValues, enableCDN: item.key });
                setErrors({ ...errors, enableCDN: undefined });
              }}
              className={classes.mb8}
              options={[
                { key: "true", text: "Yes" },
                { key: "false", text: "No" },
              ]}
              disabled={
                formValues.product === "Relocate" ||
                formValues.licenseType == LICENSE_TYPE.Training
              }
            />
            <CloudFrameTextBox
              label="Jira Ticket Link (URL)"
              className={classes.mb8}
              value={formValues.jiraTicket}
              disabled={!!data}
              errorMessage={errors.jiraTicket}
              onChange={(_, value) => {
                setFormValues({ ...formValues, jiraTicket: value });
                const errorMessage = !jiraTicketPattern.test(value || "")
                  ? "Please provide valid Jira ticket"
                  : undefined;
                setErrors({ ...errors, jiraTicket: errorMessage });
              }}
              required
            />

            <div style={{ marginTop: "1.5rem" }}>
              <CloudFrameCheckbox
                boxSide="end"
                label="Offline license"
                styles={{
                  label: {
                    fontWeight: 600,
                  },
                }}
                name="isOfflineLicense"
                checked={formValues.isOfflineLicense}
                onChange={(_: any, checked: any) => {
                  setFormValues({
                    ...formValues,
                    isOfflineLicense: !!checked,
                  });
                }}
                disabled={!!data}
              />
            </div>
            <div className={classes.btnContainer}>
              <CloudFramePrimaryButton
                text={data ? "Renew" : "Create"}
                type="submit"
                disabled={
                  formSubmitting ||
                  (Number.isInteger(data?.id) &&
                    data?.status !== LICENSE_STATUS.EXPIRED)
                }
                className={`${classes.mt16} ${classes.ml2}`}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LicenseForm;
