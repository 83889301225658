import { gql } from "@apollo/client";

export const MUTATION_DATABASE_BACKUP = gql`
  mutation BackupDatabase($id: Float!) {
    backupDatabase(id: $id) {
      status
      message
    }
  }
`;

export const MUTATION_DATABASE_RESTORE = gql`
  mutation RestoreDatabase($backupInfoId: Float!) {
    restoreDatabase(id: $backupInfoId) {
      status
      message
    }
  }
`;
