import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DialogType,
  IColumn,
  IIconProps,
  Icon,
  IconButton,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import {
  MUTATION_APPROVE_LICENSE,
  MUTATION_DECLINE_LICENSE,
  MUTATION_REVOKE_LICENSE,
} from "../../graphql-mutations/license.mutation";
import {
  QUERY_GET_DCIO_LICENSE_TEXT,
  QUERY_GET_LICENSES,
  QUERY_GET_LICENSE_TEXT,
} from "../../graphql-query/license.query";
import { formatDateTime } from "../../utils/date.utils";
import { saveStringAsTxt } from "../../utils/file.utils";
import { IActionButtonTitle } from "../action-buttons/action-button.intefaces";
import ActionButtons from "../action-buttons/action-buttons";
import Authorization from "../auth/authorization";
import { useStyles } from "./license-list.style";
import { UserPermission } from "../../constants/permissions.constants";
import { preserveDateTimeFormat } from "../../utils/date.utils";
import { IDialogProps as IMessageDialogProps } from "../../@types/form.types";
import {
  LICENSE_STATUS,
  LICENSE_TYPE,
  PRODUCT,
} from "../../constants/license.constants";
import { useFormStyles } from "../../@styles/form.style";

const LicenseList = () => {
  const licenseListQuery = useQuery(QUERY_GET_LICENSES);
  const [loadLicenseText] = useLazyQuery(QUERY_GET_LICENSE_TEXT);
  const [loadDcioLicenseText] = useLazyQuery(QUERY_GET_DCIO_LICENSE_TEXT);
  const [approveLicense] = useMutation(MUTATION_APPROVE_LICENSE);
  const [declineLicense] = useMutation(MUTATION_DECLINE_LICENSE);
  const [licenseList, setLicenseList] = useState<Array<any>>([]);
  const [activeItem, setActiveItem] = useState<any>({});
  const [hideDialog, setHideDialog] = useState(true);
  const [approvingLicense, setApprovingLicense] = useState(false);
  const [showLicenseDetails, setShowLicenseDetail] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<any>({});
  const [messgeInfo, setMessageInfo] = useState<IMessageDialogProps>();
  const [messageHidden, setMessageHidden] = useState(true);
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");
  const [searchParams, setSearchParams] = useSearchParams();
  const [denialReason, setDenialReason] = useState("");
  const [denialError, setDenialError] = useState("");

  const [revokeLicense] = useMutation(MUTATION_REVOKE_LICENSE);

  const classes = useStyles();
  const contClasses = useContainerStyles();
  const formClasses = useFormStyles();
  const navigate = useNavigate();

  const searchIcon: IIconProps = { iconName: "Search" };

  useEffect(() => {
    loadLicenseList();
  }, [licenseListQuery.data]);

  useEffect(() => {
    const lid = searchParams.get("lid");
    if (lid) {
      if (licenseList) {
        const approvingLicense = licenseList.find(
          (lic) => lic.licenseId === lid
        );
        if (approvingLicense) {
          setSelectedLicense({ ...approvingLicense });
          setShowLicenseDetail(true);
          setSearchParams(undefined);
        }
      }
    }
  }, [licenseList]);

  const loadLicenseList = async () => {
    await licenseListQuery.refetch();
    let data: Array<any> = licenseListQuery.data?.getAllLicenses;
    if (data) {
      const formattedData = data.map((license) => {
        return {
          ...license,
          effectiveDate: preserveDateTimeFormat(license.effectiveDate),
          expiryDate: preserveDateTimeFormat(license.expiryDate),
        };
      });
      setLicenseList([...formattedData]);
    }
  };

  const resetLicenseList = async () => {
    await licenseListQuery.refetch();
    loadLicenseList();
  };

  const onEditLicense = () => {
    navigate(`/edit-license`, {
      state: {
        licenseId: activeItem.item.id,
      },
    });
  };

  const hideConfirmationDialog = () => {
    setHideDialog(true);
  };

  const onRevokeLicense = async () => {
    if (!activeItem.item?.id) {
      return;
    }
    hideConfirmationDialog();
    const result = await revokeLicense({
      variables: {
        id: activeItem.item.id,
      },
    });
    if (result.data.revokeLicense) {
      await licenseListQuery.refetch();
    }
  };

  const onViewLicense = (row: any) => {
    setSelectedLicense(row);
    setShowLicenseDetail(true);
  };

  const isPendingStatus = (license: any) => {
    return license.status === "PENDING";
  };

  const displayMessage = () => {
    setMessageHidden(false);
    setTimeout(() => {
      setMessageHidden(true);
    }, 2000);
  };

  const onApproveLicense = async () => {
    setApprovingLicense(true);
    const licenseId = selectedLicense.id;
    const data = {
      variables: {
        id: licenseId,
      },
    };
    const message: IMessageDialogProps = {
      title: "License Approval Status",
      message: "License approved successfully",
    };
    try {
      const result = await approveLicense(data);
      if (result.data && result.data?.approveLicense.status === 1) {
        setMessageInfo(message);
        await resetLicenseList();
        setShowLicenseDetail(false);
      } else {
        message.message = "Failed to approve license";
      }
    } catch (e) {
      message.message = "Request failed";
      console.error("Error approving license: ", e);
    }
    displayMessage();
    setApprovingLicense(false);
  };

  const onDeclineLicense = async () => {
    setApprovingLicense(true);
    if (!denialReason) {
      setDenialError(
        "Please provide reason for declination to decline the license"
      );
      setApprovingLicense(false);
      return;
    }
    const licenseId = selectedLicense.id;
    const data = {
      variables: {
        id: licenseId,
        declineReason: denialReason,
      },
    };
    const message: IMessageDialogProps = {
      title: "License Decline Status",
      message: "License declined successfully",
    };
    try {
      const result = await declineLicense(data);
      if (result.data && result.data?.declineLicense.status === 1) {
        setMessageInfo(message);
        await resetLicenseList();
        setShowLicenseDetail(false);
      } else {
        message.message = "Failed to decline license";
      }
    } catch (e) {
      message.message = "Request failed";
      console.error("Error declining license: ", e);
    }
    displayMessage();
    setApprovingLicense(false);
  };

  // for creating dropdown filter in the status
  // const getLicenseStatusOptions = (): IDropdownOption<any>[] => {
  //   const statuses = Object.values(LICENSE_STATUS).map((status) => {
  //     return { key: status, text: `Status (${status})` };
  //   });
  //   return [{ key: "", text: "Status (All)" }, ...statuses];
  // };

  const downloadCFLicense = async () => {
    const licenseId = activeItem.item.id;
    const customerName = activeItem.item.customerName;

    if (!licenseId || !customerName) return;

    const { data } = await loadLicenseText({ variables: { id: licenseId } });
    if (data?.getLicense) {
      const licenseFileName =
        activeItem.item.product === PRODUCT.DCIO_PLUS_SUBSCRIPTION
          ? "CloudFrame_DCIO_Subscription_License"
          : "Cloudframe_License";
      // download license file
      saveStringAsTxt(data.getLicense.licenseText || "", licenseFileName);
    }
  };

  const downloadDcioLicense = async () => {
    const licenseId = activeItem.item.id;
    const customerName = activeItem.item.customerName;

    if (!licenseId || !customerName) return;

    const { data } = await loadDcioLicenseText({
      variables: { id: licenseId },
    });
    if (data?.getLicense) {
      // download license file
      saveStringAsTxt(
        data.getLicense.dcioLicenseText || "",
        `CloudFrame_DCIO_Subscription_License`
      );
    }
  };

  const getActions = (props: any): IActionButtonTitle => {
    const actionButtonTitles: IActionButtonTitle = {
      viewTitle: "View License",
      deleteTitle: "Revoke License",
      downloadTitle: "Download License",
    };

    if (props.status === LICENSE_STATUS.EXPIRED) {
      actionButtonTitles.editTitle = "Renew License";
    }

    if (props.licenseType === LICENSE_TYPE.SI && props.includeDcio) {
      actionButtonTitles.downloadDcioTitle = "Download DCIO License";
    }
    return actionButtonTitles;
  };

  const columns: IColumn[] = [
    {
      key: "id",
      name: "SL",
      fieldName: "id",
      minWidth: 45,
      maxWidth: 90,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      // isFiltered: false,
      data: "string",
      isPadded: false,
      onRender: (license, index) => <p>{index ? index + 1 : license.id}</p>,
    },
    {
      key: "customerName",
      name: "Customer name",
      fieldName: "customerName",
      minWidth: 132,
      maxWidth: 264,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.customerName}</p>,
    },
    {
      key: "licenseType",
      name: "License type",
      fieldName: "licenseType",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.licenseType}</p>,
    },
    {
      key: "product",
      name: "Product",
      fieldName: "product",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.product}</p>,
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: false,
      onRender: (props) => <p>{props.status}</p>,
      // onRenderHeader: (props) => {
      //   return (
      //     // <CloudFrameDropdown
      //     //   placeholder="Select an option"
      //     //   label="License Type"
      //     //   defaultSelectedKey={[""]}
      //     //   onChange={(_e, item: any) => {
      //     //     loadLicensesByStatus(item.key);
      //     //   }}
      //     //   options={getLicenseStatusOpeions()}
      //     // />

      //   );
      // },
    },
    {
      key: "action",
      name: "Action",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      data: "string",
      onRender: (props) => {
        return (
          <ActionButtons
            onEdit={onEditLicense}
            onDelete={() => setHideDialog(false)}
            onView={onViewLicense}
            onDownload={downloadCFLicense}
            onDownloadDcio={downloadDcioLicense}
            row={props}
            buttonTitles={getActions(props)}
          />
        );
      },
      isPadded: false,
    },
  ];

  const dialogStyles = { main: { maxWidth: 450 } };

  const modalProps = {
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: undefined,
  };

  const loadLicensesByStatus = (status: LICENSE_STATUS) => {
    const statusStr: string = LICENSE_STATUS[status];
    const licenses = licenseListQuery.data?.getAllLicenses;
    if (status) {
      const data = licenses.filter((l: any) => l.status === statusStr);
      setLicenseList(data);
    } else {
      setLicenseList([...licenses]);
    }
  };

  const filterList = (event: any) => {
    const licenses = licenseListQuery.data?.getAllLicenses;
    if (!event.currentTarget.value) {
      setTimeout(() => {
        setLicenseList(licenses);
      });
    }
    let textFilter = event.currentTarget.value;
    if (textFilter) {
      textFilter = textFilter?.toLowerCase();
      const data = licenses.filter(
        (l: any) =>
          `${l.customerName}`.toLowerCase().includes(textFilter) ||
          `${l.product}`.toLowerCase().includes(textFilter) ||
          `${l.licenseType}`.toLowerCase().includes(textFilter) ||
          `${l.status}`.toLowerCase().includes(textFilter)
      );
      setLicenseList(data);
    } else {
      setLicenseList([...licenses]);
    }
  };

  return (
    <div style={{ marginTop: "3rem" }}>
      <h3>All Licenses</h3>
      <hr />
      <div className={contClasses.flexStartContainer}>
        <CloudFrameTextBox
          placeholder="Search"
          iconProps={searchIcon}
          style={{ width: "20rem" }}
          onChange={(e) => filterList(e)}
        />
        <CloudFramePrimaryButton
          title="Filters all the licenses which are in pending status"
          style={{ padding: "1rem" }}
          text="Pending for Approval List"
          iconProps={{ iconName: "DocumentApproval" }}
          onClick={() => {
            loadLicensesByStatus(LICENSE_STATUS.PENDING);
          }}
        />
        <CloudFramePrimaryButton
          style={{ padding: "1rem" }}
          title="Refresh List"
          iconProps={{ iconName: "refresh" }}
          onClick={() => {
            loadLicenseList();
          }}
        />
      </div>
      <div style={{ width: "auto", marginTop: "1rem" }}>
        <CloudFrameDataTable
          items={licenseList || []}
          selectionMode={SelectionMode.none}
          columns={columns}
          className={classes.tableBody}
          onActiveItemChanged={(item, index) => setActiveItem({ item, index })}
          onShouldVirtualize={()=>false}
        />
      </div>
      <CloudFrameDialog
        hidden={hideDialog}
        onDismiss={hideConfirmationDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Revoke Access Confirmation",
          closeButtonAriaLabel: "Close",
          subText: "Are you sure you want to revoke this license?",
        }}
        modalProps={modalProps}
        primaryButton={{ text: "Revoke", onClick: onRevokeLicense }}
        secondaryButton={{ text: "Cancel", onClick: hideConfirmationDialog }}
      />
      <CloudFrameDialog
        hidden={messageHidden}
        onDismiss={() => setMessageHidden(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: messgeInfo?.title,
          closeButtonAriaLabel: "Close",
          subText: messgeInfo?.message,
        }}
        modalProps={modalProps}
        primaryButton={{ text: "Close", onClick: () => setMessageHidden(true) }}
      />
      <Modal
        titleAriaId={"titleId"}
        isOpen={showLicenseDetails}
        onDismiss={() => setShowLicenseDetail(false)}
        isModeless={false}
        isBlocking={true}
        // containerClassName={contentStyles.container}
        dragOptions={undefined}
        styles={{
          main: { borderRadius: 20, overflowY: "hidden", position: "fixed" },
        }}
      >
        <div style={{ width: "35rem", padding: "2rem" }}>
          <div className={contClasses.flexSpaceBtwnContainer}>
            <Icon iconName="TextDocument" style={{ fontSize: "2rem" }} />
            <IconButton
              iconProps={{ iconName: "Cancel", style: { fontSize: "1.3rem" } }}
              ariaLabel="Close popup modal"
              onClick={() => setShowLicenseDetail(false)}
            />
          </div>
          <h3>License details</h3>
          <hr />
          <div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Id</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.licenseId}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Customer Name</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.customerName}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Email</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.email}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Effective Date</strong>
              </p>
              <p style={{ flex: 1 }}>
                {formatDateTime(new Date(selectedLicense.effectiveDate))}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Expiry date</strong>
              </p>
              <p style={{ flex: 1 }}>
                {formatDateTime(new Date(selectedLicense.expiryDate))}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>License Type</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.licenseType}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Product</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.product}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Lines of code</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.linesOfCode}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>License Mode</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isOfflineLicense ? "Embedded" : "Subscription"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Customer is a reseller</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isCustomerReseller ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Product training provided</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.productTrainingProvided ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Evaluation license</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isEvaluationLicense ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Enabled CDN</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.enableCDN ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Lines of code category</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.linesOfCodeCategory === 0
                  ? "Copybook included in programs"
                  : "Copybook Separated from programs"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Support level</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.supportLevel}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "50%" }}>
                <strong>Allowed programs for migration</strong>
              </p>
              <p style={{ width: "50%", wordWrap: "break-word" }}>
                {selectedLicense.allowedProgram}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "50%" }}>
                <strong>Allowed JCLs for migration</strong>
              </p>
              <p style={{ width: "50%", wordWrap: "break-word" }}>
                {selectedLicense.allowedJcl}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Consulting hours</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.consultingHours}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Jira Ticket</strong>
              </p>
              <p style={{ flex: 1 }}>
                <a target="_blank" href={selectedLicense.jiraTicket}>
                  {selectedLicense.jiraTicket}
                </a>
              </p>
            </div>
            {selectedLicense.status === "DECLINED" && (
              <>
                <div style={{ display: "flex" }}>
                  <p style={{ flex: 1 }}>
                    <strong>Decline Reason</strong>
                  </p>
                  <p style={{ flex: 1 }}>{selectedLicense.denialReason}</p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ flex: 1 }}>
                    <strong>Declined By</strong>
                  </p>
                  <p style={{ flex: 1 }}>{selectedLicense.approvedBy}</p>
                </div>
              </>
            )}
            {isPendingStatus(selectedLicense) && (
              <>
                <hr />
                <h3 style={{ marginTop: 50 }}>License Approval/Declination</h3>
                <hr />
                <CloudFrameTextBox
                  label="License Decline Reason"
                  title="User must explain reason for decline license if declining the license is necessary"
                  value={denialReason}
                  errorMessage={denialError}
                  onChange={(_, value) => {
                    setDenialReason(value || "");
                    setDenialError("");
                  }}
                  required
                />

                <div className={contClasses.flexEndContainer}>
                  <Authorization>
                    <CloudFramePrimaryButton
                      key={UserPermission.P_APPROVE_LICENSE}
                      title="Approve License"
                      text="Approve License"
                      disabled={approvingLicense}
                      onClick={onApproveLicense}
                    />

                    <CloudFramePrimaryButton
                      key={UserPermission.P_DECLINE_LICENSE}
                      title="Decline License"
                      text="Decline License"
                      disabled={approvingLicense}
                      style={{
                        backgroundColor: "red",
                      }}
                      onClick={onDeclineLicense}
                    />
                  </Authorization>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LicenseList;
