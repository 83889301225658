import { makeStyles } from "@fluentui/react-components"

export const useStyles = makeStyles({
    tableBody: {
        width: 'auto',
        height: '30rem',
        overflowY: 'auto',
        overflowX:"auto",
        display:'block',
        "@media(min-width: 1250px)": {
            overflowX: 'auto',
        }
    }
})