import { gql } from "@apollo/client";

export const MUTATION_CREATE_REGISTRATION_KEY = gql`
mutation AddActivationKey($licenseId: Float!, $email: String) {
  addActivationKey(licenseId: $licenseId, email: $email) {
    message
    status
  }
}
`;
