import { CloudFramePrimaryButton } from "@cloudframe/button";
import { FormEvent, ReactElement, useMemo, useState } from "react";
import { useStyles } from "./registration-key-form.styles";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { useMutation } from "@apollo/client";
// import { useNavigate } from "react-router-dom";
import { MUTATION_CREATE_REGISTRATION_KEY } from "../../graphql-mutations/registration-key.mutation";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDropdownProps,
  ITextFieldProps,
} from "@fluentui/react";
import CustomLabel from "../custom-label/custom-label";
import { useBoolean } from "@fluentui/react-hooks";
import { isEmailValid } from "../../utils/string.utils";
import Authorization from "../auth/authorization";
import { UserPermission } from "../../constants/permissions.constants";
import { checkSsoProfile } from "../../utils/ms.graph.utils";

const dialogStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
  type: DialogType.normal,
  title: "Operation Status",
  closeButtonAriaLabel: "Close",
  subText: "",
};

const RegistrationKeyForm = () => {
  const classes = useStyles();
  const [licenseId, setLicenseId] = useState("");
  const [email, setEmail] = useState("");
  const [licenseError, setLicenseError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createRegistrationKey] = useMutation(MUTATION_CREATE_REGISTRATION_KEY);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const modalProps = useMemo(
    () => ({
      titleAriaId: "3",
      subtitleAriaId: "4",
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: undefined,
    }),
    []
  );

  // const navigate = useNavigate();

  const getLicenseIdValidationMsg = (licenseId: string | undefined) => {
    return !licenseId ? "Please provide valid license Id" : "";
  };

  const getEmailValidationMsg = async (email: string | undefined) => {
    if (email) {
      if (!isEmailValid(email)) {
        return "Please provide valid email address";
      } else {
        const isActiveSsoUser: boolean = await checkSsoProfile(email);
        if (!isActiveSsoUser) {
          return "Email must be SSO enabled";
        }
      }
    }
    return "";
  };

  const validateForm = async () => {
    const licenseErr = getLicenseIdValidationMsg(licenseId);
    const emailErr = await getEmailValidationMsg(email);

    setLicenseError(licenseErr);
    setEmailError(emailErr);
    return !licenseErr && !emailErr;
  };

  const onResetForm = (): void => {
    setLicenseId("");
    setEmail("");
    setLicenseError("");
    setEmailError("");
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitting(true);
    const isValid = await validateForm();
    try {
      if (isValid) {
        const result = await createRegistrationKey({
          variables: {
            licenseId: parseInt(licenseId),
            email: email,
          },
        });
        const operationStatus = result.data?.addActivationKey || {
          status: 2,
        };
        if (operationStatus.status === 1) {
          // navigate("/all-registration-keys");
          dialogContentProps.subText = `Successfully generated registration key and sent mail to ${
            email ? "the given email address" : "your email address."
          }`;
          onResetForm();
          toggleHideDialog();
        } else {
          dialogContentProps.subText =
            operationStatus.message || "Failed to generate registration key";
          toggleHideDialog();
        }
      }
    } catch (e) {
      console.error(e);
      dialogContentProps.subText =
        "Error occured. Failed to create registration key";
      toggleHideDialog();
    }
    setFormSubmitting(false);
  };

  const onRenderLabel = (
    props: ITextFieldProps | IDropdownProps | undefined
  ): ReactElement =>
    props ? (
      <CustomLabel id={props.label?.replaceAll(" ", "")} {...props} />
    ) : (
      <div />
    );

  return (
    <Authorization>
      <div
        key={UserPermission.P_CREATE_ACTIVATION_KEY}
        className={classes.root}
      >
        <div
          id="form"
          className={classes.formBody}
          style={{ borderRadius: "25px" }}
        >
          <div className={classes.subContainer}>
            <h3 style={{ marginBottom: "3rem" }}>Create Activation Key</h3>
            <form onSubmit={onFormSubmit}>
              <CloudFrameTextBox
                onChange={(_, value = "") => {
                  const lid = value ? value.trim() : "";
                  const err = getLicenseIdValidationMsg(lid);
                  setLicenseId(lid);
                  setLicenseError(err);
                }}
                value={`${licenseId}`}
                label="License Id"
                ariaLabel="License ID is a field in the 'Cloudframe_License.txt' file. Usually its a large number to identify the license for which the activation key will be generated."
                required
                className={classes.mb8}
                description={licenseError ? "" : " "}
                errorMessage={licenseError}
                onRenderLabel={onRenderLabel}
              />
              <CloudFrameTextBox
                onChange={async (_, value = "") => {
                  const eml = value ? value.trim() : "";
                  setEmail(eml);
                  if (eml) {
                    const err = getEmailValidationMsg(eml);
                    setEmailError(await err);
                  }
                }}
                value={email}
                label="Email"
                placeholder="Email address"
                ariaLabel="Email address where the activation key will be sent. It must be a CloudFrame SSO enabled email address (must end with @cloudframe.com). If no email address given here, email will be sent to current user."
                type="string"
                className={classes.mb8}
                // description={emailError ? "" : " "}
                // errorMessage={emailError}
                onRenderLabel={onRenderLabel}
                errorMessage={emailError}
              />
              <div className={classes.btnContainer}>
                <CloudFramePrimaryButton
                  text={"Create"}
                  type="submit"
                  disabled={formSubmitting}
                  className={`${classes.mt16} ${classes.ml2}`}
                />
              </div>
            </form>
          </div>
        </div>
        <Dialog
          hidden={hideDialog}
          onDismiss={toggleHideDialog}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
        >
          <DialogFooter>
            <DefaultButton onClick={toggleHideDialog} text="Close" />
          </DialogFooter>
        </Dialog>
      </div>
    </Authorization>
  );
};

export default RegistrationKeyForm;
