import "./progress-loader.css"; // Import CSS for styling

function ProgressLoader(props?) {
  return (
    <div
      className="loader-container"
      style={{
        ...props?.style,
        visibility: props?.show ? "visible" : "hidden",
      }}
    >
      <div className="loader"></div>
    </div>
  );
}

export default ProgressLoader;
