import { CloudFrameIconButton } from "@cloudframe/button";

export const BackButton = (props: any) => {
  const { onClick } = props;

  return (
    <div>
      <CloudFrameIconButton
        iconProps={{
          iconName: "Back",
          style: {
            transform: "scaleX(2) scaleY(1.5)",
            marginBlock: "5px",
            marginRight: "1.5rem",
          },
        }}
        style={{ width: "4rem" }}
        title="Back"
        onClick={onClick}
      />
    </div>
  );
};
