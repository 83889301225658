import { useMemo } from "react";
import ActiveShapePieChart from "../../../components/active-shape-pie-chart/active-shape-pie-chart";
import { ILicenseUsageOverview } from "./license-usage-oberview.interface";

const getPieChartData = (usedLines: number, unusedLines: number) => [
  {
    name: "Used Lines",
    value: usedLines,
    fill: "rgba(250,0,0,1)",
    centerTextFill: "rgba(200,40,40,1)",
  },
  {
    name: "Unused Lines",
    value: unusedLines < 0 ? 0 : unusedLines,
    fill: "rgba(0,250,0,1)",
    centerTextFill: "rgba(25,150,40,1)",
  },
];

const LicenseUsageOverview = (props: ILicenseUsageOverview) => {
  const { licenseData } = props;

  const totalLines = licenseData.linesOfCode;
  const usedLines = useMemo(() => {
    if (licenseData.licenseHistory) {
      return licenseData.licenseHistory.reduce(
        (acc, obj) => acc + obj.linesUsed,
        0
      );
    }
    return 0;
  }, [licenseData]);
  const unusedLines = totalLines - usedLines;

  const pieChartData = useMemo(() => {
    return getPieChartData(usedLines, unusedLines);
  }, [usedLines, unusedLines]);

  return (
    <div className="dashboard-tile" style={{ minWidth: "30rem", padding: 0, }}>
      <h3
        style={{
          margin: 0,
          padding: "1.3rem",
          borderBottom: "1px solid #eee",
          color: "black",
        }}
      >
        License usage overview
      </h3>
      <div style={{ padding: "1rem" }}>
        <div style={{ height: "16rem" }}>
          <ActiveShapePieChart data={pieChartData} />
        </div>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            borderTop: "1px solid #eee",
            borderRight: "1px solid #eee",
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ padding: ".5rem" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "grey" }}>
              Used lines
            </p>
            <h3
              style={{
                margin: 0,
                color: "rgba(255,0,0,.7)",
                marginTop: "4px",
              }}
            >
              {usedLines}
            </h3>
          </div>
        </div>
        <div
          style={{
            borderTop: "1px solid #eee",
            borderLeft: "1px solid #eee",
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ padding: ".5rem" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "grey" }}>
              Unused lines
            </p>
            <h3
              style={{
                margin: 0,
                color: "rgba(0,255,0,.7)",
                marginTop: "4px",
              }}
            >
              {unusedLines}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseUsageOverview;
