import {
  IPersonaSharedProps,
  Persona,
  PersonaSize,
  PersonaPresence,
  Stack,
  Nav,
  INavStyles,
  INavLinkGroup,
  initializeIcons,
  IStyle,
  INavLink,
} from "@fluentui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { getInitials } from "../../utils/string.utils";
import { useSelector } from "react-redux";
import { UserInfo } from "../../@types/auth.types";
import { createObjectFromKeysArray } from "../../utils/array.utils";
import { UserPermission } from "../../constants/permissions.constants";

initializeIcons();
const navStyles: Partial<INavStyles> = {
  root: {
    width: 350,
    height: "100%",
    boxSizing: "border-box",
    overflowY: "auto",
    paddingTop: "1rem",
    paddingLeft: "2rem",
    paddingRight: "1rem",
  },
};

const personaStyles: IStyle = {
  border: "1px solid #eee",
  padding: "12px",
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: "Home",
        url: "/",
        key: "P_HOME",
        expandAriaLabel: "Expand Home section",
        collapseAriaLabel: "Collapse Home section",
      },
    ],
  },
  {
    name: "Administration",
    collapseByDefault: true,
    links: [
      {
        name: "User List",
        url: "/user-list",
        key: "P_USER_LIST",
      },
      {
        name: "User Role Management",
        url: "/user-role",
        key: "P_USER_ROLE_FORM,P_USER_ROLE_LIST",
      },
      {
        name: "Invite User",
        url: "/invite-user",
        key: "P_INVITE_USER,P_INVITATION_LIST",
      },
      {
        name: "License Audit Trail",
        url: "/license-audit-trail",
        key: "P_LICENSE_AUDIT_TRAIL",
      },
      {
        name: "Application Log",
        url: "/logs",
        key: "P_APP_LOGS",
      },
      {
        name: "Archive & Restore DB",
        url: "/db-backup-restore",
        key: "P_DB_BACKUP,P_DATABASE_RESTORE",
      },
    ],
  },
  {
    name: "License Management",
    collapseByDefault: true,
    links: [
      {
        name: "Licenses List",
        url: "/all-licenses",
        key: "P_LICENSE_LIST",
      },
      {
        name: "Create License",
        url: "/create-license",
        key: "P_CREATE_NEW_LICENSE",
      },
      {
        name: "Activation Key",
        url: "/activation-key",
        key: "P_CREATE_ACTIVATION_KEY",
      },
    ],
  },
  // {
  //   links: [
  // {
  //   name: "Dashboard Client",
  //   url: "/dashboard-client",
  //   key: "/dashboard-client",
  //   isExpanded: true,
  // },

  // {
  //   name: "Contact us",
  //   url: "/contact-us",
  //   key: "/contact-us",
  // },
  //   ],
  // },
];

const filterLinks = (links: INavLink[], permissions: any) => {
  return links.filter((link) => {
    const keys = `${link.key}`.split(",");
    for (const key of keys) {
      if (permissions[key]) {
        return true;
      }
    }
    return false;
  });
};

const getNavLinkGroups = (
  user: UserInfo,
  navLinkGroups: INavLinkGroup[]
): INavLinkGroup[] => {
  if (user && user.username) {
    if (user.roles.includes("Admin")) {
      return navLinkGroups;
    } else {
      const permissionKeys = createObjectFromKeysArray(user.permissions);
      return navLinkGroups.filter((group) => {
        group.links = filterLinks(group.links, permissionKeys);
        return group.links.length > 0;
      });
    }
  }
  return [];
};

const NavBasicExample = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: UserInfo) => state);
  return (
    <Nav
      selectedKey={location.pathname}
      ariaLabel="Nav basic example"
      styles={{ ...navStyles, link: { fontSize: "1rem" } }}
      groups={getNavLinkGroups(user, navLinkGroups)}
      onLinkClick={(e, item) => {
        e?.preventDefault();
        navigate(item?.url ?? "/");
      }}
    />
  );
};

export const SideNav = () => {
  const msl = useMsal();
  const user = useSelector((state: UserInfo) => state);

  const persona1: IPersonaSharedProps = {
    imageInitials: getInitials(msl.accounts?.[0]?.name || "User"),
    text: msl.accounts?.[0]?.name || "User",
    secondaryText: user.roles[0],
    tertiaryText: "",
    optionalText: user.roles[1] || "",
    presence: PersonaPresence.online,
  };

  return (
    <Stack
      tokens={{ childrenGap: 10 }}
      style={{ display: "static", height: "98%" }}
    >
      <div
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          //borderRight: "1px solid #eee",
        }}
      >
        <Persona {...persona1} size={PersonaSize.size72} />
      </div>
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          border: "1px solid #eee",
          borderRadius: 5,
        }}
      >
        <NavBasicExample />
      </div>
    </Stack>
  );
};
