import { useContainerStyles } from "../@styles/container.styles";
import Authorization from "../components/auth/authorization";
import LicenseSummaryPage from "../components/license-summary/license-summary";
import { UserPermission } from "../constants/permissions.constants";

const Home = () => {
  const contClasses = useContainerStyles();
  return (
    <Authorization>
      <section
        key={UserPermission.P_HOME}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div style={{ marginTop: "3rem" }}>
              <div style={{ paddingLeft: "1rem", width: "90%" }}></div>
              <LicenseSummaryPage />
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default Home;
