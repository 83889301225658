import { Display } from "@fluentui/react-components";

const StatsCard = (props: any) => {
  const { icon, value, title, description } = props;
  return (
    <div
      style={{
        //backgroundColor: "rgba(255,255,5,.1)",
        width: "20rem",
        //padding: "1rem",
        //borderRadius: 4,
        paddingTop: 10,
        paddingBottom: 10,
        //border: "1px solid #eee",
        borderRadius: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 30,
        height: "4rem",
        padding: "1rem",
        margin: ".2rem",
      }}
    >
      <i style={{ marginLeft: "1rem" }}>{icon}</i>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: title?"center": "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: ".5rem",
            marginBottom: ".5rem",
          }}
        >
          <h3
            style={{
              fontSize: "2rem",
              marginTop: "0rem",
              marginBottom: "0rem",
              marginRight: 0,
            }}
          >
            {value}
          </h3>{" "}
          {title && (
            <span
              style={{
                backgroundColor: "#eee",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "4px",
                paddingBottom: "4px",
                borderRadius: 20,
              }}
            >
              {title}
            </span>
          )}
        </div>
        <p
          style={{
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            // maxWidth: "8rem",
            // textAlign: "center",
          }}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default StatsCard;
