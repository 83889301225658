import { makeStyles } from "@fluentui/react-components"

export const useStyles = makeStyles({
    tableBody: {
        maxWidth: '100%',
        overflowY: 'auto',
        overflowX:"auto",
        display:'block',
        "@media(min-width: 1250px)": {
            overflowX: 'auto',
        }
    }
})