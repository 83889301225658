import { AzureProfile } from "../@types/auth.types";

export const checkSsoProfile = async (userEmail: string): Promise<boolean> => {
  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/users?$filter=mail eq '${userEmail}'`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("cfa")}`,
        },
      }
    );
    const profile = await response.json();
    return !profile.error && profile.value?.length > 0;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const getActiveUsers = async (): Promise<AzureProfile[]> => {
  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/users?$filter=AccountEnabled eq true`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("cfa")}`,
        },
      }
    );
    const profiles = await response.json();
    return profiles.value || [];
  } catch (e) {
    console.log(e);
  }
  return [];
};
