/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLazyQuery, useQuery } from "@apollo/client";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  IColumn,
  IGroup,
  IIconProps,
  Icon,
  IconButton,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { LicenseSummary } from "../../@types/license.types";
import {
  QUERY_GET_CDN_STATISTICS,
  QUERY_GET_LICENSE_SUMMARY,
} from "../../graphql-query/license.query";
import {
  formatDateTime,
  getEOD,
  toISOShortDateString,
} from "../../utils/date.utils";
import { downloadHtmlAsPdf } from "../../utils/file.utils";
import { useStyles } from "./license-summary.style";

const LicenseSummaryPage = () => {
  const licenseListQuery = useQuery(QUERY_GET_LICENSE_SUMMARY);
  const [fetchCdnStatistics] = useLazyQuery(QUERY_GET_CDN_STATISTICS);
  const [cdnStatList, setCdnStatList] = useState<Array<any>>([]);
  const [filteredCdnStatList, setFilteredCdnStatList] = useState<Array<any>>(
    []
  );
  const [licenseList, setLicenseList] = useState<Array<any>>([]);
  const [licenseGroups, setLicenseGroups] = useState<IGroup[]>([]);
  const [showLicenseDetails, setShowLicenseDetail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCdnStatusOpen, setCdnStatusOpen] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<any>({});

  const classes = useStyles();
  const contClasses = useContainerStyles();

  const searchIcon: IIconProps = { iconName: "Search" };

  useEffect(() => {
    loadLicenseList().then(() => console.log(""));
  }, [licenseListQuery.data]);

  const createGroups = (licenses: LicenseSummary[]) => {
    let groupData: IGroup[] = [];
    let currentGroup: any = null;
    let startIndex = 0;

    for (let i = 0; i < licenses.length; i++) {
      const currentItem = licenses[i];

      if (currentGroup === null || currentItem.customerName !== currentGroup) {
        if (currentGroup !== null) {
          groupData[groupData.length - 1].count = i - startIndex;
        }
        currentGroup = currentItem.customerName;
        startIndex = i;
        groupData.push({
          key: currentGroup,
          name: currentGroup,
          startIndex: startIndex,
          count: 0,
        });
      }
    }

    if (groupData.length > 0) {
      groupData[groupData.length - 1].count = licenses.length - startIndex;
    }

    setLicenseGroups([...groupData]);
  };

  const loadLicenseList = async () => {
    let data = licenseListQuery.data?.getLicenseSummary as LicenseSummary[];
    if (data) {
      const formattedData = data.map((license) => {
        return {
          ...license,
        };
      });
      createGroups(formattedData);
      setLicenseList([...formattedData]);
    }
  };
  const resetCdnStats = () => {
    setCdnStatList([]);
    setFilteredCdnStatList([]);
  };

  const showCdnStatus = async (license) => {
    resetCdnStats();
    setLoading(true);
    setCdnStatusOpen(true);
    setSelectedLicense(license);
    try {
      const result = await fetchCdnStatistics({
        variables: {
          licenseId: license.licenseId,
        },
      });
      setCdnStatList([...result.data?.getLicenseHistoryByLicenseId]);
      setFilteredCdnStatList([...result.data?.getLicenseHistoryByLicenseId]);
    } catch (e) {
    }
    setLoading(false);
  };

  const onViewLicense = (row: any) => {
    setSelectedLicense(row);
    setShowLicenseDetail(true);
  };

  const isExpired = (license: any) => {
    const expiryDate = getEOD(new Date(license.expiryDate));
    return (
      new Date() > expiryDate || license.linesOfCodeUsed >= license.linesOfCode
    );
  };

  const licenseDetailsColumns: IColumn[] = [
    {
      key: "customerName",
      name: "Customer Name",
      fieldName: "customerName",
      minWidth: 180,
      maxWidth: 240,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "licenseId",
      name: "License ID",
      fieldName: "licenseId",
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
      onRender: (props) => (
        <span
          className={contClasses.link}
          style={{ color: isExpired(props) ? "red" : "darkblue" }}
          onClick={() => {
            onViewLicense(props);
          }}
        >
          {props.licenseId}
        </span>
      ),
    },
    {
      key: "licenseType",
      name: "License type",
      fieldName: "licenseType",
      minWidth: 90,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "expiryDate",
      name: "Expiry Date",
      fieldName: "expiryDate",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "linesOfCode",
      name: "LOC Allocated",
      fieldName: "linesOfCode",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "cdnStatistics",
      name: "CDN Statistics",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
      onRender: (props) => (
        <span
          className={contClasses.link}
          onClick={async () => {
            await showCdnStatus(props);
          }}
        >
          View Statistics
        </span>
      ),
    },
  ];

  const cdnStatColumns: IColumn[] = [
    {
      key: "programHash",
      name: "Program Hash",
      fieldName: "programHash",
      minWidth: 120,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "programType",
      name: "Program type",
      fieldName: "programType",
      minWidth: 90,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "date",
      name: "Data capturing date",
      fieldName: "date",
      minWidth: 110,
      maxWidth: 130,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
      onRender: (props) => {
        return <span>{toISOShortDateString(props.date)}</span>;
      },
    },
    {
      key: "linesUsed",
      name: "Lines used",
      fieldName: "linesUsed",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "macId",
      name: "MAC ID",
      fieldName: "macId",
      minWidth: 90,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
  ];

  const filterCdnStatList = (event: any) => {
    const cdnStats = [...cdnStatList];
    if (!event.currentTarget.value) {
      setTimeout(() => {
        setFilteredCdnStatList(cdnStats);
      });
    }
    let textFilter = event.currentTarget.value;
    if (textFilter) {
      textFilter = textFilter?.toLowerCase();
      const data = cdnStats.filter(
        (record: any) =>
          `${record.programHash}`.toLowerCase().includes(textFilter) ||
          `${record.programType}`.toLowerCase().includes(textFilter) ||
          `${record.linesUsed}`.toLowerCase().includes(textFilter) ||
          `${record.date}`.toLowerCase().includes(textFilter) ||
          `${record.macId}`.toLowerCase().includes(textFilter)
      );
      setFilteredCdnStatList(data);
    } else {
      setFilteredCdnStatList([...cdnStats]);
    }
  };

  return (
    <div style={{ marginTop: "3rem" }}>
      <h3>Licenses</h3>
      <hr />
      <div style={{ width: "auto", marginTop: "1rem" }}>
        <CloudFrameDataTable
          groups={licenseGroups}
          items={licenseList || []}
          selectionMode={SelectionMode.none}
          columns={licenseDetailsColumns}
          className={classes.tableBody}
          onShouldVirtualize={()=>false}
        />
      </div>
      <Modal
        titleAriaId={"titleId"}
        isOpen={showLicenseDetails}
        onDismiss={() => setShowLicenseDetail(false)}
        isModeless={false}
        isBlocking={true}
        // containerClassName={contentStyles.container}
        dragOptions={undefined}
        styles={{
          main: { borderRadius: 20, overflowY: "hidden", position: "fixed" },
        }}
      >
        <div style={{ width: "35rem", padding: "2rem" }}>
          <div className={contClasses.flexSpaceBtwnContainer}>
            <Icon iconName="TextDocument" style={{ fontSize: "2rem" }} />
            <IconButton
              iconProps={{ iconName: "Cancel", style: { fontSize: "1.3rem" } }}
              ariaLabel="Close popup modal"
              onClick={() => setShowLicenseDetail(false)}
            />
          </div>
          <h3>License details</h3>
          <hr />
          <div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Id</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.licenseId}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Customer Name</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.customerName}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Email</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.email}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Effective Date</strong>
              </p>
              <p style={{ flex: 1 }}>
                {formatDateTime(new Date(selectedLicense.effectiveDate))}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Expiry date</strong>
              </p>
              <p style={{ flex: 1 }}>
                {formatDateTime(new Date(selectedLicense.expiryDate))}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>License Type</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.licenseType}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Product</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.product}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Lines of code</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.linesOfCode}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>License Mode</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isOfflineLicense ? "Embedded" : "Subscription"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Customer is a reseller</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isCustomerReseller ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Product training provided</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.productTrainingProvided ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Evaluation license</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.isEvaluationLicense ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Enabled CDN</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.enableCDN ? "Yes" : "No"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Lines of code category</strong>
              </p>
              <p style={{ flex: 1 }}>
                {selectedLicense.linesOfCodeCategory === 0
                  ? "Copybook included in programs"
                  : "Copybook Separated from programs"}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Support level</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.supportLevel}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "50%" }}>
                <strong>Allowed programs for migration</strong>
              </p>
              <p style={{ width: "50%", wordWrap: "break-word" }}>
                {selectedLicense.allowedProgram}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ width: "50%" }}>
                <strong>Allowed JCLs for migration</strong>
              </p>
              <p style={{ width: "50%", wordWrap: "break-word" }}>
                {selectedLicense.allowedJcl}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Consulting hours</strong>
              </p>
              <p style={{ flex: 1 }}>{selectedLicense.consultingHours}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1 }}>
                <strong>Jira Ticket</strong>
              </p>
              <p style={{ flex: 1 }}>
                <a target="_blank" href={selectedLicense.jiraTicket}>
                  {selectedLicense.jiraTicket}
                </a>
              </p>
            </div>
            {selectedLicense.status === "DECLINED" && (
              <>
                <div style={{ display: "flex" }}>
                  <p style={{ flex: 1 }}>
                    <strong>Decline Reason</strong>
                  </p>
                  <p style={{ flex: 1 }}>{selectedLicense.denialReason}</p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ flex: 1 }}>
                    <strong>Declined By</strong>
                  </p>
                  <p style={{ flex: 1 }}>{selectedLicense.approvedBy}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        titleAriaId={"cdnStatusModal"}
        isOpen={isCdnStatusOpen}
        onDismiss={() => setCdnStatusOpen(false)}
        isModeless={false}
        isBlocking={true}
        // containerClassName={contentStyles.container}
        dragOptions={undefined}
        styles={{
          main: { borderRadius: 20, overflowY: "hidden", position: "fixed" },
        }}
      >
        <div style={{ width: "64rem", padding: "2rem" }}>
          <div className={contClasses.flexSpaceBtwnContainer}>
            <Icon iconName="TextDocument" style={{ fontSize: "2rem" }} />
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "1rem" }}>
                <CloudFrameTextBox
                  placeholder="Search"
                  iconProps={searchIcon}
                  style={{ width: "20rem" }}
                  onChange={(e) => filterCdnStatList(e)}
                />
              </div>
              <IconButton
                iconProps={{
                  iconName: "DownloadDocument",
                  style: { fontSize: "1.3rem" },
                }}
                style={{ marginRight: "1rem" }}
                ariaLabel="Download as PDF"
                title="Download as PDF"
                onClick={() =>
                  downloadHtmlAsPdf(
                    "cdnStatReport",
                    `cdn_statistics_${selectedLicense.licenseId}`
                  )
                }
              />
              <IconButton
                iconProps={{
                  iconName: "Cancel",
                  style: { fontSize: "1.3rem" },
                }}
                ariaLabel="Close popup modal"
                onClick={() => setCdnStatusOpen(false)}
              />
            </div>
          </div>
          <div id="cdnStatReport">
            <h3>CDN Statistics</h3>
            <hr />
            <div className={contClasses.flexSpaceBtwnContainer}>
              <div>
                <strong>License Id: </strong>
                <span>{selectedLicense.licenseId}</span>
              </div>
              <div>
                <strong>Total LOC allocated : </strong>
                <span>{selectedLicense.linesOfCode}</span>
              </div>
              <div>
                <strong>Total LOC used : </strong>
                <span>
                  {cdnStatList.reduce((accumulator, currentValue) => {
                    return (
                      accumulator +
                      (currentValue.linesUsed
                        ? Number(currentValue.linesUsed)
                        : 0)
                    );
                  }, 0)}
                </span>
              </div>
            </div>
            <div>
              <div style={{ width: "auto", marginTop: "1rem" }}>
                <CloudFrameDataTable
                  setKey="items"
                  items={filteredCdnStatList}
                  columns={cdnStatColumns}
                  selectionMode={SelectionMode.none}
                  // onRenderItemColumn={onRenderItemColumn}
                  // enableShimmer={isLoading}
                  // ariaLabelForShimmer="Loading..."
                  ariaLabelForGrid="CDN Statistics"
                  listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                  }}
                />
                {(!filteredCdnStatList || filteredCdnStatList.length < 1) && (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    No data found
                  </div>
                )}
                <br />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LicenseSummaryPage;
