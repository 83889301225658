/* eslint-disable no-shadow */
import React, { PureComponent } from "react";
import { PieChart, Pie, Cell } from "recharts";

const RADIAN = Math.PI / 180;
const example = [
  { name: "A", value: 80, color: "#ff0000" },
  { name: "B", value: 45, color: "#00ff00" },
  { name: "C", value: 25, color: "#0000ff" },
];
const cx = 170;
const cy = 150;
const iR = 80;
const oR = 120;

const needle = (
  value: any,
  data: any,
  cx: any,
  cy: any,
  iR: any,
  oR: any,
  color: any
) => {
  let total = 0;
  data.forEach((v: any) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      d={`M${xba},${yba}L${xbb},${ybb},L${xp},${yp},L${xba},${yba}`}
      stroke="#none"
      fill={color}
    />,
  ];
};

const NeedlePieChart = (props: any) => {
  const { data, value } = props;
  const percentText = `${(value).toFixed(2)}%`;
  return (
    <PieChart width={300} height={300}>
      <Pie
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="#8884d8"
        stroke="none"
      >
        {data.map((entry: any, index: number) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {needle(value, data, cx, cy, iR, oR, "#d0d000")}
      <text
        x={cx - 40}
        y={cy + 20}
        dy={18}
        textAnchor={"textAnchor"}
        fill="#999"
        fontSize={"1.2rem"}
      >
        {"Used lines"}
      </text>
      <text
        x={cx - (value > 9 && value < 100 ? 37 : value === 100 && value ? 40 : 25)}
        y={cy + 50}
        dy={18}
        textAnchor={"textAnchor"}
        fill="#999"
        fontSize={"1.7rem"}
        fontWeight="bold"
      >
        {percentText}
      </text>
    </PieChart>
  );
};

export default NeedlePieChart;
