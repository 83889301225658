import {
  DialogType,
  IDropdownProps,
  IIconProps,
  ITextFieldProps,
  initializeIcons,
} from "@fluentui/react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CloudFrameDefaultButton,
  CloudFramePrimaryButton,
} from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import {
  IColumn,
  Icon,
  IconButton,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { ReactElement, useEffect, useState } from "react";
import { MUTATION_PERSIST_ROLE } from "../../graphql-mutations/role.mutation";
import { QUERY_GET_ROLES_WITH_PERMISSIONS } from "../../graphql-query/role.query";
import { QUERY_GET_PERMISSIONS } from "../../graphql-query/permission.query";
import ActionButtons from "../../components/action-buttons/action-buttons";
import { useId } from "@fluentui/react-hooks";
import CustomLabel from "../../components/custom-label/custom-label";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { useContainerStyles } from "../../@styles/container.styles";
import { useListStyles } from "../../@styles/list.styles";
import Authorization from "../../components/auth/authorization";
import { UserPermission } from "../../constants/permissions.constants";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { loadCss } from "../../utils/file.utils";

const RolesPage = () => {
  const [persistRole] = useMutation(MUTATION_PERSIST_ROLE);
  const roleListQuery = useQuery(QUERY_GET_ROLES_WITH_PERMISSIONS);
  const permissionListQuery = useQuery(QUERY_GET_PERMISSIONS);
  const [activeItem, setActiveItem] = useState<any>({});
  const [hideDialog, setHideDialog] = useState(true);
  const [showRoleDetails, setShowRoleDetail] = useState(false);
  const [selectedRole, setSelectedRole] = useState<any>({});
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");
  const [dialogProps, setDialogProps] = useState<any>({});
  const [formValues, setFormValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [formSubmitting, setFormSubmitting] = useState(false);
  const contClasses = useContainerStyles();
  const listClasses = useListStyles();
  const [displayData, setDisplayData] = useState([]);
  const [fullDisplayData, setFullDisplayData] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState<any[]>([]);
  const [showEditView, setEditView] = useState(false);

  const roleTitlePattern = /^[A-Za-z][A-Za-z0-9_ ]*$/g;

  const searchIcon: IIconProps = { iconName: "Search" };

  const columns: IColumn[] = [
    {
      key: "name",
      name: "Role Title",
      fieldName: "name",
      minWidth: 120,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => <p>{props.name}</p>,
    },
    {
      key: "permissions",
      name: "Permitted features",
      fieldName: "permissions",
      minWidth: 160,
      maxWidth: 600,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
      onRender: (props) => {
        return (
          <div className={contClasses.flexStartContainer}>
            {props?.permissions?.map((permission: any, index: number) => {
              const comma = `${
                index + 1 === props?.permissions.length ? "" : ","
              }`;
              return (
                <span
                  key={permission.name}
                >{`${permission.name}${comma}`}</span>
              );
            })}
          </div>
        );
      },
    },
    {
      key: "action",
      name: "Action",
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      data: "string",
      onRender: (props) => {
        return (
          <ActionButtons
            onEdit={onEditRole}
            onDelete={() => setHideDialog(false)}
            onView={onViewRole}
            row={props}
            buttonTitles={actionButtonTitles}
          />
        );
      },
      isPadded: true,
    },
  ];

  const actionButtonTitles = {
    editTitle: "Edit Role",
  };

  const showDialog = (message: string, title?: string) => {
    setDialogProps({
      title: title ? title : "Role Operation",
      message: message,
    });
    setHideDialog(false);
    setTimeout(() => {
      setHideDialog(true);
    }, 2000);
  };

  const setData = async (data?: { getRoles: any }) => {
    let list = [];
    if (data) {
      list = data?.getRoles;
    } else {
      list = (await roleListQuery.data?.getRoles) || [];
    }
    setFullDisplayData(list);
    setDisplayData(list);
  };

  const resetData = async () => {
    const result = await roleListQuery.refetch();
    setData(result.data);
  };

  const resetForm = () => {
    setFormValues({
      name: "",
      permissionIds: [],
    });
  };

  const onEditRole = () => {
    setEditView(true);
    const formData = {
      id: activeItem.item?.id,
      name: activeItem.item?.name,
      permissionIds: activeItem.item?.permissions.map((r: any) => `${r.id}`),
    };
    setFormValues(formData);
  };
  const onViewRole = (row: any) => {
    setSelectedRole(row);
    setShowRoleDetail(true);
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!formValues.name || !roleTitlePattern.test(formValues.name?.trim())) {
      newErrors.name = "Please provide valid role title";
    }
    setErrors({ ...newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    if (!formValues.permissionIds?.length) {
      return showDialog("Please select at least one permission");
    }
    setFormSubmitting(true);
    const isFormValid = validateForm();
    if (isFormValid) {
      try {
        const permissionIdsAsString = formValues.permissionIds.join(",");
        const result = await persistRole({
          variables: { ...formValues, permissionIds: permissionIdsAsString },
        });
        if (result?.data?.persistRole) {
          showDialog(
            `Role information ${
              formValues.id ? "updated" : "stored"
            } successfully`
          );
          setFormSubmitting(false);
          resetForm();
          setEditView(false);
          await resetData();
          return;
        }
      } catch (e) {
        showDialog("Something went wrong!");
        console.error(e);
      }
    } else {
      showDialog("Please fill the form properly");
    }
    setFormSubmitting(false);
  };

  const onRenderLabel = (
    props: ITextFieldProps | IDropdownProps | undefined
  ): ReactElement => {
    return props ? (
      <CustomLabel id={props.label?.replaceAll(" ", "")} {...props} />
    ) : (
      <div />
    );
  };

  const onFilterChange = (event: any) => {
    const filterText = event.target.value?.trim().toLowerCase();
    if (!filterText) {
      setTimeout(() => {
        setDisplayData(fullDisplayData);
      });
    } else {
      let filteredData = fullDisplayData.filter((item: any) => {
        const name = item.name.trim().toLowerCase();
        const permissionNames = item.permissions.map((i: any) => i.name);
        const permissions = permissionNames.toString().toLowerCase();
        const nameMatched = name.includes(filterText);
        const permissionMatched = permissions.includes(filterText);
        return permissionMatched || nameMatched;
      });
      setDisplayData(filteredData);
    }
  };

  initializeIcons();

  useEffect(() => {
    setFormValues({ ...formValues, permissionIds: [] });
    setDialogProps({
      title: "Role Operation",
      message: "",
    });
    loadCss(
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    );
  }, []);

  useEffect(() => {
    setData();
  }, [roleListQuery.data]);

  useEffect(() => {
    if (permissionListQuery.data) {
      let permissionOptionData = permissionListQuery.data?.getPermissions.map(
        (p) => {
          return { value: `${p.id}`, label: p.name };
        }
      );
      setPermissionOptions([...permissionOptionData]);
    }
  }, [permissionListQuery.data]);

  return (
    <Authorization>
      <section
        key={UserPermission.P_USER_ROLE_LIST}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <h3 style={{ marginBottom: "3rem" }}>User Role List</h3>
            <div style={{ marginTop: "1rem" }}>
              <CloudFrameTextBox
                placeholder="Search"
                iconProps={searchIcon}
                onChange={(e) => onFilterChange(e)}
                className={listClasses.searchBar}
              />
              <CloudFrameDataTable
                compact={true}
                items={displayData?.filter(
                  (role: any) => role.name !== "Admin"
                )}
                selectionMode={SelectionMode.none}
                columns={columns}
                className={listClasses.tableBody}
                onActiveItemChanged={(item, index) =>
                  setActiveItem({ item, index })
                }
              />
            </div>
            <Modal
              titleAriaId={"titleId"}
              isOpen={showEditView}
              onDismiss={() => {
                resetForm();
                setEditView(false);
              }}
              isModeless={false}
              isBlocking={true}
              // containerClassName={contentStyles.container}
              dragOptions={undefined}
              styles={{
                main: {
                  borderRadius: 20,
                  overflowY: "hidden",
                  position: "fixed",
                },
              }}
            >
              <div
                style={{
                  width: "64rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <div className={contClasses.flexEndContainer}>
                  <IconButton
                    iconProps={{
                      iconName: "Cancel",
                      style: { fontSize: "1.3rem" },
                    }}
                    ariaLabel="Close popup modal"
                    onClick={() => {
                      resetForm();
                      setEditView(false);
                    }}
                  />
                </div>
              </div>
              <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
                <h3>Edit User Role</h3>
                <CloudFrameTextBox
                  label="Role Title"
                  ariaLabel="Please provide role title."
                  className={contClasses.mb8}
                  defaultValue={formValues?.name}
                  value={formValues?.name}
                  errorMessage={errors?.name}
                  onChange={(_, value = "") => {
                    setFormValues({
                      ...formValues,
                      name: value,
                    });
                    const error = roleTitlePattern.test(value)
                      ? ""
                      : "Please input only letters, numbers, space (' ') or '_'. First character must be letter";
                    setErrors({ ...errors, name: error });
                  }}
                  onRenderLabel={onRenderLabel}
                  required
                />
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label title="Users with this Role will gain access to the selected permissions">
                      Select User Permissions
                    </label>
                    <CustomLabel
                      id="lbl-role-permission"
                      ariaLabel="Please select the permissions to be associated with the Role in the above input text"
                    />
                  </div>
                  <div>
                    <DualListBox
                      options={permissionOptions}
                      selected={formValues.permissionIds}
                      onChange={(value) => {
                        const formData = { ...formValues };
                        formData.permissionIds = value;
                        setFormValues({ ...formData });
                      }}
                    />
                  </div>
                </div>
                <div className={contClasses.flexEndContainer}>
                  <CloudFramePrimaryButton
                    text="Update"
                    type="button"
                    onClick={onFormSubmit}
                    disabled={formSubmitting}
                  />
                </div>
              </div>
            </Modal>
            <CloudFrameDialog
              hidden={hideDialog}
              onDismiss={() => {
                setHideDialog(true);
              }}
              dialogContentProps={{
                type: DialogType.normal,
                title: dialogProps?.title,
                closeButtonAriaLabel: "Close",
                subText: dialogProps?.message,
              }}
              modalProps={{
                titleAriaId: labelId,
                subtitleAriaId: subTextId,
                isBlocking: false,
                styles: { main: { maxWidth: 400 } },
                dragOptions: undefined,
              }}
              primaryButton={{
                text: "Close",
                onClick: () => {
                  setHideDialog(true);
                },
              }}
            />
          </div>
        </div>
      </section>

      <section
        key={UserPermission.P_USER_ROLE_FORM}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div className={contClasses.subContainer}>
              <h3>User Role Form</h3>
              <CloudFrameTextBox
                label="Role Title"
                ariaLabel="Please provide role title."
                className={contClasses.mb8}
                defaultValue={formValues?.name}
                value={formValues?.name}
                errorMessage={errors?.name}
                onChange={(_, value = "") => {
                  setFormValues({
                    ...formValues,
                    name: value,
                  });
                  const error = roleTitlePattern.test(value)
                    ? ""
                    : "Please input only letters, numbers, space (' ') or '_'. First character must be letter";
                  setErrors({ ...errors, name: error });
                }}
                onRenderLabel={onRenderLabel}
                required
              />
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label title="Users with this Role will gain access to the selected permissions">
                    Select User Permissions
                  </label>
                  <CustomLabel
                    id="lbl-role-permission"
                    ariaLabel="Please select the permissions to be associated with the Role in the above input text"
                  />
                </div>
                <div>
                  <DualListBox
                    options={permissionOptions}
                    selected={formValues.permissionIds}
                    onChange={(value) => {
                      const formData = { ...formValues };
                      formData.permissionIds = value;
                      setFormValues({ ...formData });
                    }}
                  />
                </div>
              </div>
              <div className={contClasses.flexEndContainer}>
                <CloudFramePrimaryButton
                  text="Submit"
                  type="button"
                  onClick={onFormSubmit}
                  disabled={formSubmitting}
                />
                <CloudFrameDefaultButton
                  text="Reset"
                  type="button"
                  onClick={async () => {
                    resetForm();
                    await resetData();
                  }}
                  disabled={formSubmitting}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default RolesPage;
